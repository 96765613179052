import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import TextArea from '../../../../SharedComponents/TextAreaInput/TextAreaInput'
import { CalendarDays, Plus } from 'lucide-react'
import TextInput from '../../../../SharedComponents/TextInput/TextInput'
import OperationalCalendarSelector from '../../../../EnterpriseApp/OperationalCalendar/OperationalCalendarSelector/OperationalCalendarSelector'
import { format, startOfToday } from 'date-fns'
import useBackendService from '../../../../../../config/services/backend-service'
import Modal from '../../../../SharedComponents/Modal/Modal'
import GlobalButton from '../../../../SharedComponents/GlobalButton/GlobalButton'

const GenerateOutput = ({ id }) => {
  const [scheduledEvents, setScheduledEvents] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false
  })

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({ mode: 'onChange' })
  const today = startOfToday()
  const [selectedDay, setSelectedDay] = useState({})

  const handleDateChange = (index, selectedDay) => {
    if (selectedDay && !isNaN(new Date(selectedDay))) {
      setSelectedDay(prevSelectedDays => ({
        ...prevSelectedDays,
        [index]: selectedDay
      }))
      setValue(`date_${index}`, format(new Date(selectedDay), 'dd-MM-yyyy'))
    } else {
      setSelectedDay(prevSelectedDays => ({
        ...prevSelectedDays,
        [index]: undefined
      }))
      setValue(`date_${index}`, '')
    }
  }

  const addScheduledEvent = () => {
    setScheduledEvents([...scheduledEvents, { event: '', date: '' }])
    setSelectedDay(prevSelectedDays => ({
      ...prevSelectedDays,
      [scheduledEvents.length]: ''
    }))
  }
  const { mutate: generateOutput, isLoading } = useBackendService(
    `/calendar/${id}`,
    'put',
    {
      onSuccess: () => {
        setModal({
          type: 'success',
          title: 'Farm calendar updated',
          desc: 'The farm calendar output has been successfully updated.',
          link: '/admin-requests',
          visible: true
        })
        setModalOpen(true)
      },
      onError: error => {
        setModal({
          type: 'error',
          title: 'Unable to update farm calendar',
          desc: 'There was an issue updating the farm calendar output. Please try again.',
          visible: true
        })
        setModalOpen(true)
      }
    }
  )
  const onSubmit = async data => {
    const events = scheduledEvents
      .map((event, index) => ({
        event: data[`event_${index}`],
        date: data[`date_${index}`]
      }))
      .filter(event => event.event && event.date)

    const outputData = {
      returnOnInvestment: data.returninvestment,
      businessCases: data.businesscases,
      challenges: data.challenges,
      challengeMitigators: data.challengemitigators,
      valueChain: data.valuechainanalysis,
      preservationMethod: data.preservationmethods,
      harvestingMethod: data.harvestingmethods,
      plantingMethod: data.plantingmethodsandsupport,
      eventDescription: events
    }

    generateOutput(outputData)
  }
  const returninvestment = watch('returninvestment')
  const businesscases = watch('businesscases')
  const challenges = watch('challenges')
  const challengemitigators = watch('challengemitigators')
  const valuechainanalysis = watch('valuechainanalysis')
  const preservationmethods = watch('preservationmethods')
  const harvestingmethods = watch('harvestingmethods')
  const plantingmethodsandsupport = watch('plantingmethodsandsupport')
  const isfilled = returninvestment && businesscases && challenges && challengemitigators && valuechainanalysis && preservationmethods && harvestingmethods && plantingmethodsandsupport && scheduledEvents.length > 0

  return (
    <div className='w-full lg:w-[90%] bg-white'>
      <h1 className='lg:text-[18px] text-black font-[500] text-start'>
        Generate output
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className='w-full space-y-5 pt-3'>
        <div className='w-full'>
          <TextArea
            name='returninvestment'
            register={register}
            errors={errors}
            text='Return on Investment'
            placeholder='Enter here'
          />
        </div>
        <div className='w-full'>
          <TextArea
            name='businesscases'
            register={register}
            errors={errors}
            text='Business Cases'
            placeholder='Enter here'
          />
        </div>
        <div className='w-full'>
          <TextArea
            name='challenges'
            register={register}
            errors={errors}
            text='Challenges'
            placeholder='Enter here'
          />
        </div>
        <div className='w-full'>
          <TextArea
            name='challengemitigators'
            register={register}
            errors={errors}
            text='Challenge Mitigators'
            placeholder='Enter here'
          />
        </div>
        <div className='w-full'>
          <TextArea
            name='valuechainanalysis'
            register={register}
            errors={errors}
            text='Value Chain Analysis'
            placeholder='Enter here'
          />
        </div>
        <div className='w-full'>
          <TextArea
            name='preservationmethods'
            register={register}
            errors={errors}
            text='Preservation Methods'
            placeholder='Enter here'
          />
        </div>
        <div className='w-full'>
          <TextArea
            name='harvestingmethods'
            register={register}
            errors={errors}
            text='Harvesting Methods'
            placeholder='Enter here'
          />
        </div>
        <div className='w-full'>
          <TextArea
            name='plantingmethodsandsupport'
            register={register}
            errors={errors}
            text='Planting Methods and Support'
            placeholder='Enter here'
          />
        </div>

        {/* Add Scheduled Events */}
        <div className='w-full flex gap-1 justify-start items-center text-darkGreen lg:text-[14px] font-[500]'>
          <Plus onClick={addScheduledEvent} className='cursor-pointer' />
          <h1>Add scheduled events</h1>
        </div>

        {/* Render the scheduled events dynamically */}
        {scheduledEvents.map((event, index) => (
          <div
            key={index}
            className='flex flex-col lg:flex-row gap-5 lg:gap-10 items-center'
          >
            <div className='w-full lg:w-[60%]'>
              <TextInput
                name={`event_${index}`}
                register={register}
                errors={errors}
                text='Event description'
                placeholder='Enter here'
              />
            </div>
            <div className='w-full lg:w-[40%]'>
              <TextInput
                name={`date_${index}`}
                register={register}
                errors={errors}
                text='Date'
                placeholder='Input here'
                calendaricon={
                  <CalendarDays
                    size={20}
                    onClick={() =>
                      document.getElementById(`calendar_${index}`).showModal()}
                  />
                }
              />
            </div>

            {/* Pass the index to the calendar selector */}
            <OperationalCalendarSelector
              selectedDay={selectedDay[index]}
              setSelectedDay={day => handleDateChange(index, day)}
              today={today}
              onSave={() => handleDateChange(index, selectedDay[index])}
              id={`calendar_${index}`} // Assign unique ID to each calendar modal
            />
          </div>
        ))}

        <div className='w-full lg:w-[418px]'>

          <GlobalButton
            title='Submit'
            isLoading={isLoading}
            isFilled={isfilled}
          />
        </div>
      </form>
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  )
}

export default GenerateOutput
