import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import SelectInput from '../../../SharedComponents/SelectInput/SelectInput'
import { Controller } from 'react-hook-form'
import RepeatedTextInput from '../../../SharedComponents/TextInput/RepeatedTextInput'
import { Search } from 'lucide-react'
import { setProductCatalog } from '../../../../../config/store/reducers/productCatalog'

function Products ({
  onClick,
  control,
  register,
  errors,
  productCatalog,
  location,
  setLocation
}) {
  const dispatch = useDispatch()
  const defaultLocations = useSelector((state) => state.productCatalog.locations)
  const productstore = useSelector((state) => state.productCatalog)
  const cat = useSelector((state) => state.productCatalog.category)

  const handleLocationChange = updatedLocations => {
    setLocation(updatedLocations)
  }
  const handleCategoryChange = (selectedCategory) => {
    dispatch(setProductCatalog({ category: selectedCategory }))
  }
  const handleNameChange = (event) => {
    const name = event.target.value
    dispatch(setProductCatalog({ name }))
  }
  const roles = [
    { value: 'livestock', label: 'LiveStock' },
    { value: 'tools', label: 'Tools' },
    { value: 'crop', label: 'Crop' }
  ]

  const isButtonDisabled =
    !productstore.category || !productstore.name || location.length === 0

  return (
    <div className='flex flex-col lg:gap-10 gap-2'>
      <div className='w-full lg:w-[70%] flex items-center flex-col lg:flex-row gap-5 lg:gap-[60px]'>
        <TextInput
          name='name'
          register={register}
          errors={errors}
          text='Name'
          value={productCatalog.name}
          onChange={handleNameChange}
        />

        <SelectInput
          control={control}
          Controller={Controller}
          data={roles}
          title='Category'
          name='category'
          placeholder='Select Category'
          value={cat || productCatalog.category}
          onChange={handleCategoryChange}
        />
      </div>
      <div className='w-full lg:w-[70%] flex items-center flex-col lg:flex-row gap-5 lg:gap-[60px]'>
        <div className='w-full'>
          <RepeatedTextInput
            label='Location (s)'
            icon={<Search className='text-[#dddddd]' />}
            placeholder='Enter location to grow product'
            existingValues={defaultLocations}
            onValuesChange={handleLocationChange}
          />
        </div>
      </div>
      <button
        onClick={onClick}
        className={`w-full lg:w-[408px] h-[50px] ${
          !isButtonDisabled
            ? 'proceed-btn bg-darkGreen'
            : 'rounded-[8px] bg-darkash text-black'
        }`}
        disabled={isButtonDisabled}
      >
        Next
      </button>
    </div>
  )
}

export default Products
