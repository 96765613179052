import { ChevronRight, Search } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../config/contexts/authProvider'
import useBackendService from '../../../../config/services/backend-service'
import GlobalLoader from '../../SharedComponents/GlobalLoader/GlobalLoader'
import './RegularCatalog.css'

function RegularCatalog ({ unlock = false }) {
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const { user } = useAuth()

  const { mutate: catalogue, isLoading } = useBackendService(
    '/product_catalogues?status=approved',
    'get',
    {
      onSuccess: (response) => {
        setData(response.data)
        setFilteredData(response.data)
      },
      onError: (error) => {
        console.error('Failed to fetch products:', error)
      }
    }
  )

  useEffect(() => {
    catalogue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSearchChange = (e) => {
    const query = e.target.value
    setSearchQuery(query)
    filterData(query)
  }

  const filterData = (query) => {
    const filtered = data.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    )
    setFilteredData(filtered)
  }

  if (isLoading) {
    return <GlobalLoader />
  }

  return (
    <div className='w-full h-full p-[2%] bg-white'>
      {data.length > 0 && (
        <div className='w-full flex justify-start items-center'>

          <div className='w-full lg:w-[400px] h-[48px] relative flex items-center'>
            <input
              type='text'
              className='w-full h-full border-black pl-[10%] ts email-input'
              placeholder='Search product'
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <Search
              size={20}
              className='absolute left-3 transform -translate-y-1/2'
              style={{ top: '50%' }}
            />
          </div>
        </div>
      )}
      <div className='w-full flex flex-wrap justify-start gap-4 items-center mt-[2%]'>
        {filteredData.length > 0
          ? (
              filteredData.map((item) => (
                <Link
                  to={unlock ? `/market/catalog/${item.id}` : `/catalog/${item.id}`}
                  className='catalogcard'
                  key={item.id}
                >
                  <div className='w-full h-[200px]'>
                    <img
                      src={item.image_url}
                      className='w-full h-full object-cover rounded-lg'
                      alt={item.name}
                    />
                  </div>
                  <div className='w-full flex justify-between items-center mt-2'>
                    <h1>{item.name}</h1>
                    {item.season && <h1 className='text-darkGreen ts'>In season</h1>}
                  </div>
                  <p className='ts mt-2'>{item.content}</p>
                  {unlock
                    ? (
                      <Link to={`/market/catalog/${item.id}`} className='upgrade'>
                        Check Out
                        <ChevronRight />
                      </Link>
                      )
                    : user.plan === 'Pro'
                      ? (
                        <Link to={`/catalog/${item.id}`} className='upgrade'>
                          Check Out
                          <ChevronRight />
                        </Link>
                        )
                      : (
                        <h1 className='upgrade'>
                          Upgrade to see more <ChevronRight />
                        </h1>
                        )}
                </Link>
              ))
            )
          : (
            <div className='text-center flex-col w-full h-full center mt-[10%]'>
              <div className='w-[150px] lg:w-[200px] center flex-col mx-auto'>
                <img
                  alt='img'
                  src={require('../../../resources/farmemptyimage.png')}
                  className='w-full'
                />
              </div>
              <p className='ts font-semibold text-[#464665]'>No catalog yet</p>
              <p className='ts text-[#464665] w-[70%] mx-auto'>
                Sorry, we do not have any product catalog for you at the moment.
              </p>
            </div>
            )}
      </div>
    </div>
  )
}

export default RegularCatalog
