// store/productCatalog.js
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  name: '',
  category: '',
  description: '',
  nutritionMarketValue: [],
  growingTips: [],
  careInstructions: [],
  storageSolutions: '',
  packagingMethods: '',
  marketPrices: [],
  logisticsProviders: '',
  salesChannels: '',
  locations: [],
  stage: 1
}

const productCatalogSlice = createSlice({
  name: 'productCatalog',
  initialState,
  reducers: {
    setProductCatalog (state, action) {
      return { ...state, ...action.payload }
    },
    resetProductCatalog () {
      return initialState
    },
    setStage (state, action) {
      state.stage = action.payload
    },
    setLocations: (state, action) => {
      state.locations = action.payload
    },
    resetLocations: (state) => {
      state.locations = []
    }
  }
})

export const { setProductCatalog, resetProductCatalog, setLocations, setStage, resetLocations } = productCatalogSlice.actions
export default productCatalogSlice.reducer
