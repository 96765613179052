import React from 'react'

const SingleTextInput = ({
  label,
  type = 'text',
  placeholder = '',
  name,
  register,
  errors,
  validationRules,
  className = '',
  subtext
}) => {
  return (
    <div className={`flex flex-col gap-1 w-full ts ${className}`}>
      {label && <h1>{label}</h1>}
      {subtext && <p className='text-[#888888] ts font-[400]'>{subtext}</p>}
      <input
        {...register(name, validationRules)}
        type={type}
        placeholder={placeholder}
        className='email-input ts'
      />
      {errors[name] && (
        <p className='text-red-500 text-sm'>{errors[name]?.message}</p>
      )}
    </div>
  )
}

export default SingleTextInput
