import { Search } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import useBackendService from '../../../../config/services/backend-service'
import GlobalLoader from '../../SharedComponents/GlobalLoader/GlobalLoader'
import AdminConsultationDataTable from './AdminConsultationDataTable/AdminConsultationDataTable'

const AdminConsultations = () => {
  const [data, setData] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredData, setFilteredData] = useState(data)

  const { mutate: fetchConsultations, isLoading } = useBackendService(
    '/farms/owners',
    'get',
    {
      onSuccess: (response) => {
        setData(response.data)
      },
      onError: (error) => {
        console.error('Error fetching consultations:', error)
      }
    }
  )

  useEffect(() => {
    fetchConsultations({ is_parent_farm: true, approved: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const filtered = data.filter((item) =>
      item?.name?.toLowerCase().includes(searchQuery.toLowerCase())
    )
    setFilteredData(filtered)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, data])

  const pendingCount = data.length
  // Loading state
  if (isLoading) {
    return <GlobalLoader />
  }
  return (
    <div className='w-full bg-white p-[2%]'>
      <div className='w-full flex gap-3 justify-between items-center'>
        <div className='bg-darkGreen text-white border border-gray-200 rounded-[4px] gap-1 center p-2 cursor-pointer h-[50px]'>
          <h1 className='text-[14px]'>Business overview</h1>
          <div className='w-[24px] h-[24px] rounded-full bg-white text-darkGreen text-[12px] center'>
            {pendingCount}
          </div>
        </div>
        {data.length > 0 && (
          <div className='flex w-[200px] lg:w-[300px] items-center border h-[50px] rounded-lg lg:gap-1 p-1.5 lg:px-2'>
            <Search size={30} color='#DDDDDD' />
            <input
              className='bg-transparent ts appearance-none border-none lg:p-2 h-[50px] placeholder:text-[10px] placeholder:lg:text-[16px] text-gray-900 placeholder-[#888888] focus:outline-none focus:ring-0'
              type='search'
              placeholder='Search business'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        )}
      </div>

      <div className='p-4'>
        <AdminConsultationDataTable data={filteredData} />
      </div>
    </div>
  )
}

export default AdminConsultations
