import { MessageSquare } from 'lucide-react'
import React from 'react'

const ChatInterCom = () => {
  return (
    <>
      <div className='absolute right-4 bottom-6 z-[200] bg-black w-[40px] h-[40px] md:w-[50px] md:h-[50px] rounded-full center'>
        <MessageSquare fill='#fff' size={25} className='text-white' />
      </div>
    </>
  )
}

export default ChatInterCom
