import {
  ChevronDown,
  ChevronRight,
  ChevronUp,
  ListFilter,
  X
} from 'lucide-react'
import React, { useState } from 'react'

const DashBoardFilter = ({ data, onFilter }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)

  const handleSelect = (item) => {
    setSelectedItem(item)
    onFilter(selectedOption === 'By businesses' ? 'farm' : 'product', item.id)
  }

  const handleRemoveItem = () => {
    setSelectedItem(null)
    onFilter('all', null)
  }

  const handleOptionChange = (option) => {
    if (selectedOption === option) {
      setSelectedOption(null)
      setSelectedItem(null)
      onFilter('all', null)
    } else {
      setSelectedOption(option)
      setSelectedItem(null)
    }
  }

  const farms = data.map((farm) => ({
    id: farm.farm_id,
    label: farm.farm_name
  }))
  const products = [
    ...new Set(
      data.flatMap((farm) => farm.products.map((product) => product.name))
    )
  ].map((product) => ({ id: product, label: product }))

  return (
    <div className='w-[65%] flex justify-end items-center ts'>
      {selectedItem && (
        <div className='filter-btn selected-items list-none flex items-center text-sm gap-2'>
          <h1>{selectedItem.label}</h1>
          <button onClick={handleRemoveItem}>
            <X size={20} />
          </button>
        </div>
      )}
      <div className='relative'>
        <button
          className='btn m-1 center shadow-none fill flex items-center space-x-2 ts'
          onClick={() => setIsOpen(!isOpen)}
        >
          <ListFilter size={20} className='mr-2' />
          Filter {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>

        {isOpen && (
          <main className='menu drop-item w-44 '>
            <div className=' w-full h-[40px] rounded-t-lg p-3'>
              <h1>General Overview</h1>
            </div>
            <div
              className={`dropdown p-3 cursor-pointer ${
                selectedOption === 'By businesses' ? 'bg-[#E9E9E9]' : ''
              }`}
              onClick={() => handleOptionChange('By businesses')}
            >
              By businesses{' '}
              <ChevronRight size={20} className='inline-block ml-2' />
            </div>
            {selectedOption === 'By businesses' && (
              <div className='aside p-3'>
                {farms.map((item) => (
                  <div key={item.id} className='p-2 cursor-pointer'>
                    <h1 onClick={() => handleSelect(item)}>{item.label}</h1>
                  </div>
                ))}
              </div>
            )}
            <div
              className={`dropdown-item p-3 cursor-pointer ${
                selectedOption === 'By products' ? 'bg-[#E9E9E9]' : ''
              }`}
              onClick={() => handleOptionChange('By products')}
            >
              By products{' '}
              <ChevronRight size={20} className='inline-block ml-2' />
            </div>
            {selectedOption === 'By products' && (
              <div className='aside p-3'>
                {products.map((item) => (
                  <div key={item.id} className='p-2 cursor-pointer'>
                    <h1 onClick={() => handleSelect(item)}>{item.label}</h1>
                  </div>
                ))}
              </div>
            )}
          </main>
        )}
      </div>
    </div>
  )
}

export default DashBoardFilter
