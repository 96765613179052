import TextArea from '../../../SharedComponents/TextAreaInput/TextAreaInput'
import React, { useState } from 'react'
import DynamicTextInput from '../../../SharedComponents/TextInput/DynamicTextInput'
import Accordion from '../../../SharedComponents/Accordion/Accordion'
import DynamicTextArea from '../../../SharedComponents/TextAreaInput/DynamicTextArea'
import { useDispatch, useSelector } from 'react-redux'
import { setProductCatalog } from '../../../../../config/store/reducers/productCatalog'

function Catalog ({ handleNext, handlePrev, errors, isValid, register, productCatalog }) {
  const dispatch = useDispatch()
  const [isMarketVisible, setIsMarketVisible] = useState(false)
  const [tipsVisible, setTipsVisible] = useState(false)
  const [isGrowthVisible, setIsGrowthVisible] = useState(false)
  const tips = useSelector((state) => state.productCatalog.growingTips)
  const care = useSelector((state) => state.productCatalog.careInstructions)
  const handleMarketChange = () => {
    setIsMarketVisible(prev => !prev)
  }
  const handleTipsChange = () => {
    setTipsVisible(prev => !prev)
  }
  const handleGrowthChange = () => {
    setIsGrowthVisible(prev => !prev)
  }
  const handleTextAreaChange = (e) => {
    const { name, value } = e.target
    dispatch(setProductCatalog({ [name]: value }))
  }

  return (
    <div className='flex flex-col gap-4 lg:gap-10 '>
      <div className=' flex flex-col lg:gap-10 gap-2'>
        <div className=' w-full lg:w-[70%] flex items-center flex-col lg:flex-row gap-5 lg:gap-[60px] '>
          <TextArea
            name='description'
            text='Description'
            placeholder='Enter a Description'
            register={register}
            errors={errors}
            value={productCatalog.description}
            onChange={handleTextAreaChange}

          />
        </div>
      </div>
      <div className=' w-full rounded-lg flex flex-col  lg:w-[70%] border border-[#f2f2f2] '>
        <div className=' p-4 lg:p-8 border-b border-[#f2f2f2] '>
          <Accordion
            text='Add nutritional/market value'
            subtext='Enlighten users on the value'
            flipped={isMarketVisible}
            onClick={handleMarketChange}
          />
        </div>
        {isMarketVisible && (
          <div className=' p-4 lg:p-8 '>
            <DynamicTextInput
              errors={errors}
              text='Market Visible'
              register={register}

            />
          </div>
        )}
      </div>
      <div className=' w-full rounded-lg flex flex-col  lg:w-[70%] border border-[#f2f2f2] '>
        <div className=' p-4 lg:p-8 border-b border-[#f2f2f2] '>
          <Accordion
            text='Add growing tips'
            subtext='Enlighten users on the value'
            flipped={isGrowthVisible}
            onClick={handleGrowthChange}
          />
        </div>
        {isGrowthVisible && (
          <div className='w-full  p-4 lg:p-8 '>
            <DynamicTextArea
              errors={errors}
              text='Market Visible'
              register={register}
              name='growingTips'
              nextbtn='Add more tips'
              storeValue={tips}

            />
          </div>
        )}
      </div>
      <div className=' w-full rounded-lg flex flex-col  lg:w-[70%] border border-[#f2f2f2] '>
        <div className=' p-4 lg:p-8 border-b border-[#f2f2f2] '>
          <Accordion
            text='Add care instruction'
            subtext='Enlighten users on the value'
            flipped={tipsVisible}
            onClick={handleTipsChange}
          />
        </div>
        {tipsVisible && (
          <div className='w-full  p-4 lg:p-8 '>
            <DynamicTextArea
              errors={errors}
              text='Market Visible'
              register={register}
              name='careInstructions'
              nextbtn='Add more instructions'
              storeValue={care}
            />
          </div>
        )}
      </div>
      {isValid && (
        <div className=' flex flex-row w-full gap-[15px] lg:gap-[30px] '>
          <div
            className={`w-full lg:w-[210px] h-[50px] cursor-pointer ${'proceed-btn bg-[#F3F6F3] flex justify-center items-center text-darkGreen border border-darkGreen '}`}
            onClick={handlePrev}
          >
            Previous
          </div>
          <div
            className={`w-full flex cursor-pointer items-center justify-center lg:w-[210px] h-[50px] ${'proceed-btn bg-darkGreen'}`}
            onClick={handleNext}
          >
            Next
          </div>
        </div>
      )}
    </div>
  )
}

export default Catalog
