import { format } from 'date-fns'
import { Timer } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useAuth } from '../../../../config/contexts/authProvider'
import useBackendService from '../../../../config/services/backend-service'
import GlobalButton from '../GlobalButton/GlobalButton'
import SingleTextArea from '../TextAreaInput/SingleTextArea'

function NoteComponent ({ data, table }) {
  const [notes, setNotes] = useState([])
  const { user } = useAuth()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm()

  const { mutate: fetchNotes } = useBackendService(
    `/notes/?admintable=${table}&admin_table_id=${data}`,
    'get',
    {
      onSuccess: (response) => {
        setNotes(response.data)
      },
      onError: (error) => {
        console.error('Failed to fetch notes:', error)
        toast.error('Failed to fetch notes')
      }
    }
  )

  const { mutate: postNote, isLoading } = useBackendService('/notes', 'post', {
    onSuccess: () => {
      toast.success('Note added')
      fetchNotes()
      reset()
    },
    onError: (error) => {
      console.error('Failed to post note:', error)
      toast.error('Failed to post note')
    }
  })

  useEffect(() => {
    if (data) {
      fetchNotes()
    }
  }, [fetchNotes, data])

  const onSubmit = (formData) => {
    const noteData = {
      message: formData.note,
      adminTable: table,
      adminTableId: data
    }
    postNote(noteData)
  }

  const textToShow = user.role === 'approver' ? 'author' : 'approver'
  const note = watch('note')
  const isfilled = note
  return (
    <div className='lg:border-l border-t lg:border-t-0 flex flex-col gap-5 h-auto lg:h-full p-2 lg:p-5 w-full'>
      <div className='flex flex-col gap-1.5'>
        <p>Note</p>
        <p className='text-[12px] text-grey lg:text-[14px]'>
          This note is only visible to you and the {textToShow}
        </p>
      </div>

      {notes.map((note) => (
        <div key={note.id} className='rounded-lg border overflow-hidden'>
          <div className='border-b bg-[#FCFCFC] p-[15px]'>
            <p className='font-[400] text-[12px] lg:text-[14px]'>
              {note.created_by_email} added a note
            </p>
          </div>
          <div className='flex flex-col gap-5 p-5'>
            <p className='text-black font-[400] text-[12px] lg:text-[14px]'>
              {note.message}
            </p>
            <div className='flex gap-2 items-center'>
              <Timer size={18} />
              <p className='text-[#444] font-[400] text-[10px] lg:text-[12px]'>
                {format(new Date(note.created_at), 'MMMM d, yyyy h:mm a')}{' '}
              </p>
            </div>
          </div>
        </div>
      ))}

      <div>
        <form
          action=''
          className='flex flex-col gap-5 lg:gap-10 w-full'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='w-full'>
            <SingleTextArea
              label='Add a note'
              name='note'
              register={register}
              validation={{ required: 'Note is required' }}
              error={errors.note}
            />
          </div>

          <GlobalButton
            title='Post Note'
            isLoading={isLoading}
            isFilled={isfilled}
          />
        </form>
      </div>
    </div>
  )
}

export default NoteComponent
