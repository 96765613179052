import { Plus, X } from 'lucide-react'
import React, { useState } from 'react'

const DynamicTextInput = ({ existingCatalogs, errors, text, register }) => {
  const [catalogs, setCatalogs] = useState(
    existingCatalogs || [{ title: '', value: '' }]
  )

  const handleCatalogChange = (index, field, fieldValue) => {
    const updatedCatalogs = [...catalogs]
    updatedCatalogs[index][field] = fieldValue
    setCatalogs(updatedCatalogs)
  }

  const addCatalog = () => {
    setCatalogs([...catalogs, { title: '', value: '' }])
  }

  const removeCatalog = index => {
    const updatedCatalogs = [...catalogs]
    updatedCatalogs.splice(index, 1)
    setCatalogs(updatedCatalogs)
  }

  return (
    <div className=' flex flex-col gap-4 lg:gap-8 '>
      {catalogs.map((catalog, index) => (
        <div key={index} className=' '>
          <div className='flex w-full items-center gap-1.5 ts'>
            <div className=' flex flex-col flex-[0.4] gap-1 '>
              <label htmlFor={`title-${index}`}>Title</label>
              <input
                type='text'
                name={`catalogs[${index}].title`}
                placeholder='Enter title'
                value={catalog.title}
                {...register(`nutritionMarketValue.${index}.title`)}
                onChange={e =>
                  handleCatalogChange(index, 'title', e.target.value)
                }
                className='email-input'
              />
            </div>
            <div className=' flex flex-col flex-[0.6] gap-1 '>
              <label htmlFor={`value-${index}`}>Value</label>
              <input
                type='text'
                name={`catalogs[${index}].value`}
                placeholder='Enter value'
                value={catalog.value}
                {...register(`nutritionMarketValue.${index}.value`)}
                onChange={e =>
                  handleCatalogChange(index, 'value', e.target.value)
                }
                className='email-input'
              />
            </div>
          </div>
          {index > 0 && (
            <div className=' flex justify-end items-center gap-2.5 mt-1 '>
              <X className=' text-red-300 text-[10px] ' size={14} />
              <div
                className=' text-red-300 text-[12px] cursor-pointer '
                onClick={() => removeCatalog(index)}
              >
                Remove
              </div>
            </div>
          )}
        </div>
      ))}
      <div className=' items-center flex gap-1 '>
        <Plus className=' text-darkGreen ' />
        <div
          onClick={addCatalog}
          className=' text-[12px] text-darkGreen lg:text-[14px]  cursor-pointer'
        >
          Add another
        </div>
      </div>
      {errors[text] && <p className='error-text'>{errors[text].message}</p>}
    </div>
  )
}

export default DynamicTextInput