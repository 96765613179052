import { ArrowLeft } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../../config/contexts/authProvider'
import useBackendService from '../../../../../config/services/backend-service'
import { statesInNigeria } from '../../../../data/static'
import Modal from '../../../SharedComponents/Modal/Modal'
import PasswordInput from '../../../SharedComponents/PasswordInput/PasswordInput'
import SelectInput from '../../../SharedComponents/SelectInput/SelectInput'
import GlobalButton from '../../../SharedComponents/GlobalButton/GlobalButton'
import SingleTextInput from '../../../SharedComponents/TextInput/SingleTextInput'

const AddBusinessBranch = () => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false
  })
  const [farmId, setFarmId] = useState(null)
  const [adminData, setAdminData] = useState(null)
  const { user } = useAuth()
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onChange'
  })

  const { mutate: createFarm } = useBackendService('/farms/sub_farm', 'post', {
    onSuccess: (data) => {
      setFarmId(data.data.id)
    },
    onError: (error) => {
      const errorMessage =
        error?.response?.data?.message ||
        'You are unable to create a branch at the moment.'
      setModal({
        type: 'error',
        title: 'Unable to Create a branch',
        desc: errorMessage,
        visible: true
      })
      setModalOpen(true)
    }
  })

  const { mutate: createUser, isLoading } = useBackendService('/users', 'post', {
    onSuccess: () => {
      setModal({
        type: 'success',
        title: 'Branch successfully created',
        desc: 'Well done, you have successfully created a new branch.',
        link: '/businesses',
        visible: true
      })
      setModalOpen(true)
    },
    onError: (error) => {
      const errorMessage =
        error?.response?.data?.message ||
        'You are unable to create a branch at the moment.'
      setModal({
        type: 'error',
        title: 'Unable to Create a branch',
        desc: errorMessage,
        visible: true
      })
      setModalOpen(true)
    }
  })

  const onSubmit = async (data) => {
    setAdminData({
      firstName: data.firstName,
      lastName: data.lastName,
      isAdmin: false,
      location: data.location,
      plan: 'Enterprise',
      phoneNumber: data.phoneNumber,
      role: 'administrator',
      email: data.email,
      password: data.password
    })
    createFarm({ ...data, parentFarmId: user.farmId })
  }

  useEffect(() => {
    if (farmId && adminData) {
      createUser({ ...adminData, farmId })
    }
  }, [farmId, adminData, createUser])

  const isFilled = watch('branchname') && watch('state') && watch('address')

  return (
    <div className='farmwrapper'>
      <Link to='/businesses' className='w-full'>
        <ArrowLeft size={20} />
      </Link>
      <div className='space-y-0 md:space-y-1'>
        <h1 className='lg:text-3xl text-xl text-black'>Add new branch</h1>
        <p className='text-[#666666] text-sm lg:text-sm'>
          Fill in the details of your new branch
        </p>
      </div>
      <form
        className='mt-[8%] w-full lg:w-[90%] h-full space-y-4 md:space-y-6'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='w-full flex flex-col md:flex-row gap-2'>
          <SingleTextInput
            label='Branch Name'
            name='branchname'
            register={register}
            errors={errors}
            validationRules={{ required: 'Branch name is required' }}
          />
          <SelectInput
            register={register}
            control={control}
            Controller={Controller}
            title='State'
            data={statesInNigeria}
            name='state'
          />
        </div>
        <div className='w-full lg:w-1/2'>
          <SingleTextInput
            label='Address'
            name='address'
            register={register}
            errors={errors}
            validationRules={{ required: 'Address is required' }}
          />
        </div>
        <div className='space-y-0 md:space-y-1'>
          <h1 className='lg:text-lg text-base text-black'>
            Assign admin <span className='text-darkGreen ts'>(optional)</span>
          </h1>
          <p className='text-[#666666] text-sm lg:text-sm'>
            Fill in the details of the branch admin
          </p>
        </div>
        <div className='w-full flex flex-col md:flex-row gap-2'>
          <div className='w-full lg:w-1/2'>
            <SingleTextInput
              label='Email'
              name='email'
              type='email'
              placeholder='Input Email'
              register={register}
              errors={errors}
              validationRules={{
                required: 'Email is required',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Please enter a valid email address'
                }
              }}
            />
          </div>
          <div className='w-full lg:w-1/2'>
            <PasswordInput
              register={register}
              errors={errors}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              nobottomlink='Password'
              disableCheck
            />
          </div>
        </div>
        <div className='w-full flex flex-col md:flex-row gap-2'>
          <SingleTextInput
            label='Last Name'
            name='lastName'
            placeholder='Input Last Name'
            register={register}
            errors={errors}
            validationRules={{ required: 'Last name is required' }}
          />
          <SingleTextInput
            label='First Name'
            name='firstName'
            placeholder='Input First Name'
            register={register}
            errors={errors}
            validationRules={{ required: 'First name is required' }}
          />
        </div>
        <div className='w-full lg:w-1/2'>
          <GlobalButton
            isFilled={isFilled}
            isLoading={isLoading}
            title='Create Branch'
          />
        </div>
      </form>
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  )
}

export default AddBusinessBranch
