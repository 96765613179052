import { useEffect, useState } from 'react'
import useBackendService from '../../../../config/services/backend-service'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import link from '../../../resources/link.png'

const ProPlanEmailVerification = () => {
  const { proUser } = useSelector(state => state.signUp || {})

  const [userEmail, setUserEmail] = useState(proUser?.email || '')
  const [timer, setTimer] = useState(59)

  const { mutate: resendVerification, isLoading } = useBackendService(
    '/auth/resend_verification',
    'post',
    {
      onSuccess: data => {
        toast.success('Verification link resent successfully!')
        setTimer(59)
      },
      onError: err => {
        toast.error(
          `Error resending verification link: ${
            err?.response?.data?.message || 'Please try again later.'
          }`
        )
      }
    }
  )

  useEffect(() => {
    if (proUser?.email) {
      setUserEmail(proUser.email)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let interval = null
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prev => prev - 1)
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [timer])

  const handleResend = e => {
    e.preventDefault()
    resendVerification({ email: userEmail })
  }

  return (
    <div className='content-cover'>
      <div className='w-full space-y-1'>
        <h1 className='header-text'>Verify your email address</h1>
        <p className='text-black w-[90%] mt-2'>
          Click the verification link that was sent to the email address below
          to confirm your details.
        </p>
        <p className='text-darkgreen font-bold'>{userEmail}</p>
      </div>

      <form className='mt-[8%] w-full' onSubmit={handleResend}>
        <div className='email-wrapper pb-2'>
          <img src={link} alt='link-image' />
        </div>

        <p className='text-[#4D5761] text-sm mt-2'>
          Didn’t get a verification link? Click resend{' '}
          {timer > 0 && (
            <>
              in <span className='text-darkgreen font-bold'>({timer}s)</span>
            </>
          )}
        </p>

        <button
          type='submit'
          className={`activebutton mt-10 ts hover:text-white ${
            timer > 0 || isLoading
              ? 'bg-gray-200 text-darkGreen cursor-not-allowed'
              : 'bg-darkgreen text-white cursor-pointer hover:opacity-90 hover:text-white'
          }`}
          disabled={isLoading || timer > 0}
        >
          {isLoading ? 'Resending...' : 'Resend'}
        </button>
      </form>

      <p className='login-text'>
        Have an account?{' '}
        <a href='/login' className='login-link'>
          Log in
        </a>
      </p>
    </div>
  )
}

export default ProPlanEmailVerification
