import { Download } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import useBackendService from '../../../../../config/services/backend-service'
import BackButton from '../../../SharedComponents/BackButton/BackButton'
import TextArea from '../../../SharedComponents/TextAreaInput/TextAreaInput'
import TextInput from '../../../SharedComponents/TextInput/TextInput'

const AdminReportDescription = () => {
  const {
    register,
    formState: { errors }
  } = useForm({
    mode: 'onChange'
  })

  const { id } = useParams()
  const [data, setData] = useState({})
  const { mutate: fetchreport } = useBackendService(
    `/admin/single_report/${id}`,
    'get',
    {
      onSuccess: (response) => {
        setData(response.data)
      },
      onError: (error) => {
        console.error('Error fetching report:', error)
      }
    }
  )

  useEffect(() => {
    fetchreport()
  }, [fetchreport, id])

  const truncateFileName = (fileName, charLimit) => {
    if (!fileName) {
      return 'No File'
    }

    if (fileName.length > charLimit) {
      return fileName.slice(0, charLimit) + '...'
    }
    return fileName
  }

  const handleDownload = (filePath, fileName) => {
    if (!filePath) {
      return
    }
    const link = document.createElement('a')
    link.href = filePath
    link.download = fileName
    link.click()
  }

  return (
    <div className='w-full p-[2%] bg-white'>
      <BackButton />
      <div className='w-full pt-2'>
        <h1 className='text-[18px] font-[500] text-darkGreen'>
          Report overview
        </h1>
        <h1 className='text-[14px] font-[400] text-[#666666]'>
          Report details
        </h1>
      </div>

      <div className='w-full pt-4 space-y-4'>
        <div className='w-[30%]'>
          <TextInput
            text='Amount charged'
            register={register}
            name='amount'
            errors={errors}
            dvalue={`₦${parseFloat(data.amount || 0).toFixed(2)}`}
            readOnly
          />
        </div>
        <div className='w-[80%]'>
          <TextArea
            name='description'
            register={register}
            errors={errors}
            text='Description'
            value={data.description || ''}
            readOnly
          />
        </div>

        {data.attachments && data.attachments.length > 0 && (
          <div className='w-full space-y-4'>
            <h1>{`${data.attachments.length} Attachment(s)`}</h1>
            {data.attachments.map((attachment, index) => (
              <div
                key={attachment.id}
                className='w-[20%] rounded-lg bg-[#EEEEEE] h-[200px] relative'
              >
                <div className='w-full flex justify-end items-center p-2'>
                  <Download
                    size={20}
                    onClick={() =>
                      handleDownload(attachment.file_path, attachment.file_name)}
                    className='cursor-pointer'
                  />
                </div>
                <div className='w-full absolute bottom-0 left-0 right-0 bg-[#DDDDDD] p-3 flex justify-start gap-2 items-center text-black'>
                  <img
                    alt={attachment.file_type}
                    src={
                      attachment.file_type === 'application/pdf'
                        ? require('../../../../resources/pdf.png')
                        : require('../../../../resources/png.png')
                    }
                    className='w-[25px] h-[25px]'
                  />
                  <h1>{truncateFileName(attachment.file_name, 15)}</h1>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default AdminReportDescription
