import React, { useEffect, useState } from 'react'
import SelectInput from '../../SharedComponents/SelectInput/SelectInput'
import { Controller, useForm } from 'react-hook-form'
import EnterpriseFarmRegistration from './EnterpriseFarmRegistration'
import TextInput from '../../SharedComponents/TextInput/TextInput'
import EnterpriseBusinessTypeSelector from './EnterpriseBusinessTypeSelector'
import { ChevronDown } from 'lucide-react'
import { statesInNigeria } from '../../../data/static'
import { useDispatch, useSelector } from 'react-redux'
import { setBusinessDetails, setEnterpriseSelectedAnswer, setFarmDetails } from '../../../../config/store/reducers/signUpReducer'

const EnterpriseBusinessDetails = ({ next, onAnswerSelect }) => {
  const [selectedValue, setSelectedValue] = useState('')
  const dispatch = useDispatch()
  const { enterpriseSelectedAnswer, farmDetails, businessDetails } = useSelector(state => state.signUp)
  const enterpriseFarmname = farmDetails ? farmDetails.farmname : ''
  const enterpriseLocation = farmDetails ? farmDetails.location : ''
  const enterpriseStaffs = farmDetails ? farmDetails.staffs : ''
  const enterpriseBranch = farmDetails ? farmDetails.branch : ''
  const enterpriseFarmYear = farmDetails ? farmDetails.year : ''
  const enterpriseBudget = businessDetails ? businessDetails.budget : ''
  const enterpriseLocationChoice = businessDetails ? businessDetails.locationchoice : ''
  const enterpriseVenture = businessDetails ? businessDetails.venture : ''
  const enterpriseBusinessType = businessDetails ? businessDetails.businesstype : ''

  const question = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ]

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      businesstype: enterpriseBusinessType || '',
      farmname: enterpriseFarmname || '',
      location: enterpriseLocation || '',
      staffs: enterpriseStaffs || '',
      branch: enterpriseBranch || '',
      year: enterpriseFarmYear || '',
      locationchoice: enterpriseLocationChoice || '',
      budget: enterpriseBudget || '',
      venture: enterpriseVenture || ''
    }
  })

  const handleSave = () => {
    if (selectedValue) {
      const selectedSubtypes = Object.keys(selectedValue)
      setValue('businesstype', selectedSubtypes, { shouldValidate: true })
    }
  }
  const selectedAnswer = watch('question')

  // Call onAnswerSelect whenever selectedAnswer changes
  useEffect(() => {
    if (onAnswerSelect) {
      onAnswerSelect(selectedAnswer)
    }
  }, [selectedAnswer, onAnswerSelect])
  useEffect(() => {
    if (enterpriseSelectedAnswer) {
      dispatch(setEnterpriseSelectedAnswer(enterpriseSelectedAnswer))
    }
  }, [enterpriseSelectedAnswer, dispatch])
  const onSubmit = async data => {
    const selection = selectedAnswer === 'Yes' ? 'Yes' : 'No'
    let filteredData

    if (selectedAnswer === 'Yes') {
      // Filter data for "Yes" selection
      const { question, ...yesFields } = data
      filteredData = { question, ...yesFields }
      // Dispatch farm details when 'Yes' is selected
      dispatch(setFarmDetails(filteredData))
    } else if (selectedAnswer === 'No') {
      // Filter data for "No" selection
      const { question, businesstype, venture, locationchoice, budget } = data
      filteredData = { question, businesstype, venture, locationchoice, budget }
      // Dispatch business details when 'No' is selected
      dispatch(setBusinessDetails(filteredData))
    }

    console.log('Filtered dataa:', filteredData)

    next(selection)
  }

  return (
    <div className='w-full h-full lg:h-full'>
      <div className='w-full space-y-1'>
        <h1 className='header-text'>Business Details</h1>
        <p className='text-black ts'>
          This details would help curate a personalized experience for you.
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className='w-full space-y-4 mt-6'>
        <SelectInput
          control={control}
          Controller={Controller}
          data={question}
          title='Do you have an agri-business'
          name='question'
          placeholder='Select answer'
        />

        {/* Conditionally render components based on selectedAnswer */}
        {selectedAnswer === 'Yes' && (
          <div className='w-full'>
            <EnterpriseFarmRegistration
              setValue={setValue}
              watch={watch}
              errors={errors}
              register={register}
            />
          </div>
        )}
        {selectedAnswer === 'No' && (
          <div className='w-full space-y-6'>
            <div
              className='w-full cursor-pointer' onClick={() =>
                document.getElementById('business').showModal()}
            >
              <TextInput
                name='businesstype'
                register={register}
                errors={errors}
                text='What type of agribusiness would you like to venture into?'
                dvalue={selectedValue || enterpriseBusinessType}
                placeholder='Select business type'
                calendaricon={
                  <ChevronDown
                    size={20}
                  />
              }
                validation={{ required: 'Business type is required' }}
              />
            </div>
            <TextInput
              name='venture'
              register={register}
              errors={errors}
              text='What are you currently involved in?'
              placeholder='Input here'
            />
            <SelectInput
              control={control}
              Controller={Controller}
              data={statesInNigeria}
              title='What is your choice of location?'
              name='locationchoice'
              placeholder='Select answer'
            />
            <TextInput
              name='budget'
              register={register}
              errors={errors}
              text='What is your financial budget?'
              placeholder='Input here'
            />
            <button
              type='submit'
              disabled={!isValid}
              className={`w-full h-[50px] ts proceed-btn rounded-[8px] ${
    !isValid ? 'bg-darkash text-black' : 'bg-darkGreen text-white'
  }`}
            >
              Continue
            </button>

            <p className='login-text'>
              Have an account?{' '}
              <a href='/login' className='login-link'>
                Log in
              </a>
            </p>
          </div>
        )}
        <EnterpriseBusinessTypeSelector
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          save={handleSave}
        />
      </form>
    </div>
  )
}

export default EnterpriseBusinessDetails
