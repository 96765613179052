import React from 'react'
import BlurFade from '../../../components/Animations/BlurFade'

const ComprehensiveToolSection = () => {
  const ComprehensiveToolSectionData = [
    {
      title: 'Real-time data monitoring',
      description:
        'Track your animal and crop health, soil moisture, and weather conditions with precision sensors and satellite imageries.',
      image: require('../../../assets/catalogimg.png')
    },
    {
      title: 'Efficient resources management',
      description:
        'Optimize use of inputs like water, fertilizers and pesticides with precise tool.',
      image: require('../../../assets/stock.png')
    },
    {
      title: 'Customizable dashboard',
      description:
        'Tailor dashboard  to display relevant data for operations. Use intuitive controls to visualize agri business metrics.',
      image: require('../../../assets/overview.png')
    },
    {
      title: 'Investment platform management',
      description:
        'Plan, manage and track your agricultural investment portfolio and multiply your margins using our robust Agri investment management tool.',
      image: require('../../../assets/investimg.png')
    }
  ]

  return (
    <section className='px-[5%] py-10 lg:py-[64px] gap-10 flex flex-col h-full w-full'>
      <BlurFade inView blur='8px' xOffset={-100} duration={0.6} delay={0.35}>
        <div className='w-full'>
          <p className='text-primary font-[600] lg:w-[50%] w-[90%] text-[20px] lg:text-[30px] text-start'>
            Comprehensive tools designed to streamline your operations
          </p>
          <p className='font-[400] text-[#666666] text-[13px] my-2 lg:text-[16px] w-[90%] lg:w-[60%] lg:my-5 text-start leading-[20px] lg:leading-[24px]'>
            Whether you're overseeing agriculture investment portfolios,
            businesses (large, medium or small scale entreprise), our features
            ensure you have everything you need to succeed
          </p>
        </div>
      </BlurFade>

      <div className='w-full grid grid-cols-1 lg:grid-cols-2 gap-6'>
        {ComprehensiveToolSectionData.map((item, index) => (
          <BlurFade
            key={index}
            inView
            blur='8px'
            delay={index * 0.35}
            duration={0.6}
          >
            <div className='rounded-lg flex flex-col justify-start items-center shadow-lg transition-all hover:grayscale '>
              {/* Top container */}
              <div className='w-full bg-gray-50 px-[5%] pt-[5%] rounded-t-lg'>
                <img src={item.image} alt='cards' className='w-full h-full' />
              </div>
              {/* Bottom container */}
              <div className='w-full border-l border-r border-b border-[#EEEEEE] rounded-b-lg space-y-1 h-[120px] px-[5%] py-5 '>
                <h1 className='font-[500] text-[14px] lg:text-[18px]'>
                  {item.title}
                </h1>
                <p className='text-[#666666] font-[400] text-[13px] lg:text-[14px]'>
                  {item.description}
                </p>
              </div>
            </div>
          </BlurFade>
        ))}
      </div>
    </section>
  )
}

export default ComprehensiveToolSection
