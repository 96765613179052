import React from 'react'
import useWindowSize from '../../../../farmapp/components/EnterpriseApp/UseWindowSize/UseWindowSize'
import BlurFade from '../../../components/Animations/BlurFade'

const NewsSubscriptionSection = () => {
  const { width } = useWindowSize()

  return (
    <>
      <section className='flex flex-col gap-5 items-center lg:px-[100px] px-3 lg:py-[64px]'>

        <BlurFade delay={0.40} inView yOffset='20px'>
          <p className='text-primary font-[600] text-[18px] lg:text-[40px] text-center lg:mb-2 lg:leading-[54px]'>
            Rapid setup, <br /> Easy Deployment
          </p>
        </BlurFade>
        {width > 1024
          ? (
            <div className='flex flex-row'>
              <BlurFade delay={0.40 * 2} inView yOffset='20px'>
                <div className='flex flex-row items-center w-full gap-3 justify-center font-[500] text-[10px] lg:text-[16px] text-[#263238]'>
                  <p>Enterprise grade security</p>
                  <div className='w-[6px] h-[6px] rounded-full bg-[#263238]' />
                  <p>Seamless onboarding</p>
                  <div className='w-[6px] h-[6px] rounded-full bg-[#263238]' />
                  <p>Timely reporting</p>
                </div>
              </BlurFade>
            </div>
            )
          : (
            <BlurFade delay={0.40 * 2} inView yOffset='20px'>
              <div className='space-y-0 font-[400]'>
                <div className='w-full text-[12px] flex justify-center items-center gap-2'>
                  <h1>Enterprise grade security</h1>
                  <div className='w-[6px] h-[6px] rounded-full bg-[#263238]' />
                  <h1>Seamless onboarding</h1>
                </div>
                <h1 className='w-full text-center text-[12px]'>Timely reporting</h1>
              </div>
            </BlurFade>
            )}

        <BlurFade inView delay={0.40 * 3} className='w-full'>
          <div className='flex flex-1 bg-[#F3F5F6] w-full lg:w-[50%] mx-auto h-[70px] py-[6px] lg:py-[8px] pl-2 lg:pl-5 pr-2 lg:pr-[1px] rounded-lg flex-row center my-5'>
            <input
              placeholder='Enter your email to subscribe to our newsletter'
              className='appearance-none leading-tight text-[#444444] text-[13px] lg:text-[14px] mt-1 w-full focus:outline-none focus:shadow-outline rounded-lg border-[0px] bg-[#F3F5F6]'
              type='text'
            />
            {width > 1024
              ? (
                <div className='bg-[#144618] text-white rounded-lg p-2 lg:px-5 py-3 lg:py-4 mr-2 lg:mr-3 cursor-pointer'>
                  <p className='text-[10px] lg:text-[14px] font-[600]'>Subscribe</p>
                </div>
                )
              : (
                <div className='bg-darkGreen px-5 py-4 rounded-[8px]'>
                  <img
                    src={require('../../../assets/download.png')}
                    alt='download'
                    className='w-[22px] h-[22px]'
                  />
                </div>
                )}
          </div>
        </BlurFade>
      </section>

      <div className='w-full relative flex items-center justify-center'>
        <h1 className='absolute font-[600] text-base text-[18px] leading-[30px] lg:text-[36px] lg:leading-[63px] text-center px-[5%] text-white'>
          We help you take charge and run your Agricultural processes more efficiently all from one platform
        </h1>
        <img
          src={require('../../../assets/plant.png')}
          className='w-full h-[200px] lg:h-[400px] object-cover'
          alt='plant'
        />
      </div>
    </>
  )
}

export default NewsSubscriptionSection
