import { ArrowLeft } from 'lucide-react'
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import useBackendService from '../../../../../config/services/backend-service'
import { resizeAndCompressImage } from '../../../../../config/services/file-resize'
import ImageInput from '../../../SharedComponents/ImageInput/ImageInput'
import Modal from '../../../SharedComponents/Modal/Modal'
import GlobalButton from '../../../SharedComponents/GlobalButton/GlobalButton'
import SingleTextInput from '../../../SharedComponents/TextInput/SingleTextInput'

function AddInvestments () {
  const [preview, setPreview] = useState(null)
  const [imageUploaded, setImageUploaded] = useState(false)
  const imageInput = useRef(null)
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false
  })

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm({ mode: 'onChange' })

  const { mutate: submitInvestment, isLoading } = useBackendService(
    '/investments',
    'post',
    {
      onSuccess: () => {
        setModal({
          title: 'Investment added',
          desc: 'Well done , you have successfully created a new investment',
          type: 'success',
          link: '/admin-investments',
          visible: true
        })
        setModalOpen(true)
        reset()
        setPreview(null)
        setImageUploaded(false)
      },
      onError: (error) => {
        setModal({
          type: 'error',
          title: 'Submission failed',
          desc: 'You are unable to create an investment',
          visible: true
        })
        setModalOpen(true)
      }
    }
  )

  const handleImageChange = async (e) => {
    const file = e.target.files?.[0]
    if (file) {
      const compressedImage = await resizeAndCompressImage(file)
      const url = URL.createObjectURL(compressedImage)
      setPreview(url)
      setValue('image', compressedImage)
      setImageUploaded(true)
    }
  }

  const handlePencilClick = () => {
    imageInput.current?.click()
  }

  const onSubmit = async (data) => {
    const { startDate, endDate, payoutDate } = data

    if (new Date(startDate) >= new Date(endDate)) {
      toast.error('Start date must be earlier than end date.')
      return
    }

    const twoWeeksAfterEndDate = new Date(endDate)
    twoWeeksAfterEndDate.setDate(twoWeeksAfterEndDate.getDate() + 14)

    if (
      new Date(payoutDate) <= new Date(endDate) ||
      new Date(payoutDate) > twoWeeksAfterEndDate
    ) {
      toast.error('Payout date must be within two weeks after the end date.')
      return
    }

    const formData = new FormData()
    formData.append('title', data.title)
    formData.append('about', data.about)
    formData.append('price', data.price)
    formData.append('availableSlot', data.slot)
    formData.append('startDate', data.startDate)
    formData.append('endDate', data.endDate)
    formData.append('investmentType', data.investmentType)
    formData.append('payoutDate', data.payoutDate)
    formData.append('repaymentPlan', data.repaymentPlan)
    formData.append('investmentRisk', data.investmentRisk)
    formData.append('status', 'pending')
    formData.append('image', data.image)

    submitInvestment(formData)
  }
  const title = watch('title')
  const about = watch('about')
  const price = watch('price')
  const slot = watch('slot')
  const startDate = watch('startDate')
  const endDate = watch('endDate')
  const investmentType = watch('investmentType')
  const payoutDate = watch('payoutDate')
  const repaymentPlan = watch('repaymentPlan')
  const investmentRisk = watch('investmentRisk')
  const isFilled = imageUploaded && title && price && slot && startDate && endDate && investmentType && payoutDate && repaymentPlan && investmentRisk && about
  return (
    <div className='flex flex-col lg:gap-10 gap-5 p-5 lg:p-10 bg-white'>
      <div>
        <a href='/admin-investments'>
          <ArrowLeft size={24} />
        </a>
      </div>
      <div className='space-y-0 lg:space-y-2'>
        <h1 className='lg:text-3xl text-xl text-black'>Add new investment</h1>
        <p className='text-[#666666] text-sm lg:text-sm'>
          Enter investment details
        </p>
      </div>
      <form
        action=''
        className='flex flex-col gap-5 lg:gap-10 max-w-fit'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='lg:w-[406px] w-full'>
          <SingleTextInput
            label='Title'
            name='title'
            register={register}
            errors={errors}
            validationRules={{ required: 'Title is required' }}
            placeholder='Input here'
          />
        </div>

        <div className='grid w-full gap-1.5'>
          <h1>Upload cover image</h1>
          <ImageInput
            preview={preview}
            imageInput={imageInput}
            handleImageChange={handleImageChange}
            handlePencilClick={handlePencilClick}
          />
          {errors.image && (
            <p className='text-red-500'>{errors.image.message}</p>
          )}
        </div>

        <div className='w-full lg:w-[876px]'>
          <SingleTextInput
            label='About'
            name='about'
            register={register}
            errors={errors}
            validationRules={{ required: 'About is required' }}
            placeholder='Input here'
          />
        </div>

        <div className='flex flex-1 gap-5 lg:gap-[60px] items-center'>
          <div className='flex-[0.5]'>
            <SingleTextInput
              label='Price'
              name='price'
              register={register}
              errors={errors}
              validationRules={{ required: 'Price is required' }}
              placeholder='Input here'
            />
          </div>
          <div className='flex-[0.5]'>
            <SingleTextInput
              label='Available slot'
              name='slot'
              register={register}
              errors={errors}
              validationRules={{ required: 'Slot is required' }}
              placeholder='Input here'
            />
          </div>
        </div>

        <div className='space-y-0 lg:text-start text-center pt-5 lg:pt-0 lg:space-y-2'>
          <h1 className='text-[18px] text-black'>Investment information</h1>
          <p className='text-[#888888] text-sm'>
            Fill out the fields to provide context
          </p>
        </div>

        <div className='flex flex-1 gap-5 lg:gap-[60px] items-center'>
          <div className='flex-[0.5]'>
            <SingleTextInput
              type='date'
              label='Start Date'
              name='startDate'
              register={register}
              errors={errors}
              validationRules={{ required: 'Start date is required' }}
              placeholder='Input here'
            />
          </div>
          <div className='flex-[0.5]'>
            <SingleTextInput
              type='date'
              label='End Date'
              name='endDate'
              register={register}
              errors={errors}
              validationRules={{ required: 'End date is required' }}
              placeholder='Input here'
            />
          </div>
        </div>

        <div className='flex flex-1 gap-5 lg:gap-[60px] items-center'>
          <div className='flex-[0.5]'>
            <SingleTextInput
              label='Investment Type'
              name='investmentType'
              register={register}
              errors={errors}
              validationRules={{ required: 'Investment Type is required' }}
              placeholder='Input here'
            />
          </div>
          <div className='flex-[0.5]'>
            <SingleTextInput
              label='Payout Date'
              type='date'
              name='payoutDate'
              register={register}
              errors={errors}
              validationRules={{ required: 'Payout date is required' }}
              placeholder='Input here'
            />
          </div>
        </div>

        <div className='w-full lg:w-[876px]'>
          <SingleTextInput
            label='Repayment Plan'
            name='repaymentPlan'
            register={register}
            errors={errors}
            validationRules={{ required: 'Repayment Plan is required' }}
            placeholder='Input here'
          />
        </div>

        <div className='w-full lg:w-[876px]'>
          <SingleTextInput
            label='Investment Risk'
            name='investmentRisk'
            register={register}
            errors={errors}
            validationRules={{ required: 'Investment Risk is required' }}
            placeholder='Input here'
          />
        </div>

        <div className='w-full lg:w-[408px]'>
          <GlobalButton
            title='Submit'
            isLoading={isLoading}
            isFilled={isFilled}
          />
        </div>
      </form>
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  )
}

export default AddInvestments
