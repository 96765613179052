import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import useBackendService from '../../../../config/services/backend-service'
import { useSelector } from 'react-redux'
import link from '../../../resources/link.png'

const BasicPlanEmailVerifcation = () => {
  const { basicUser } = useSelector(state => state.signUp || {})

  const [timer, setTimer] = useState(59)

  const [userEmail, setUserEmail] = useState(basicUser?.email || '')

  const { mutate: resendVerification, isLoading } = useBackendService(
    '/auth/resend_verification',
    'post',
    {
      onSuccess: data => {
        setTimer(59)
        toast.success('Verification link has been resent successfully!')
      },
      onError: err => {
        toast.error('Failed to resend verification link. Please try again.')
      }
    }
  )

  useEffect(() => {
    let interval = null
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prev => prev - 1)
      }, 1000)
    } else if (timer === 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [timer])

  useEffect(() => {
    if (basicUser?.email) {
      setUserEmail(basicUser.email)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleResend = e => {
    e.preventDefault()
    resendVerification({ email: userEmail })
  }

  return (
    <div className='content-cover'>
      <div className='w-full  space-y-1'>
        <h1 className='header-text'>Verify your email address</h1>
        <p className='text-black w-[90%] mt-2 '>
          Click the verification link that was sent to the email address below
          to confirm your details.
        </p>
      </div>

      <div className='mt-[8%] w-full'>
        <div className='email-wrapper pb-2'>
          <img src={link} alt='link-image' />
        </div>

        <p className='text-[#4D5761] text-sm mt-2'>
          Didn’t get a verification link? Click resend
          {timer > 0 ? ' in' : ''}
          <span className='text-darkgreen font-bold'>
            {timer > 0 ? `(${timer}s)` : ''}
          </span>
        </p>

        <button
          onClick={handleResend}
          type='submit'
          className={`activebutton mt-10 ts hover:text-white ${
            timer > 0 || isLoading
              ? 'bg-gray-200 text-darkGreen cursor-not-allowed'
              : 'bg-darkgreen text-white cursor-pointer hover:opacity-90'
          }`}
          disabled={isLoading || timer > 0}
        >
          {isLoading ? 'Resending...' : 'Resend'}
        </button>
      </div>

      <p className='login-text'>
        Have an account?{' '}
        <a href='/login' className='login-link'>
          Log in
        </a>
      </p>
    </div>
  )
}

export default BasicPlanEmailVerifcation
