import { X } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../config/contexts/authProvider'
import { updateUserPlanToPro } from '../../../../config/store/reducers/authReducer'
import {
  AddCard,
  PaymentOptions,
  usePaymentHandling
} from '../../SharedComponents/payment'

function PlanUpgrade ({ isOpen, onClose }) {
  const { user } = useAuth()
  const dispatch = useDispatch()
  const paystackPublicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
  const [cards, setCards] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)
  const [showCardFields, setShowCardFields] = useState(false)

  const [selectedPlan, setSelectedPlan] = useState({
    type: 'monthly',
    price: 5000
  })
  const handlePlanClick = (plan, price) => {
    setSelectedPlan({ type: plan, price })
  }

  const handlePostPaymentActions = () => {
    dispatch(updateUserPlanToPro())
  }

  useEffect(() => {
    if (selectedOption === 'add_card') {
      setShowCardFields(true)
      resetCardFields()
    } else if (selectedOption?.startsWith('card_')) {
      setShowCardFields(false)
      populateCardFields(selectedOption)
    } else {
      setShowCardFields(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, cards])

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isDirty, isValid }
  } = useForm({ mode: 'onChange' })

  const plan = 'Pro'
  const type = selectedPlan.type
  const totalPrice = selectedPlan.price

  const {
    fetchCards,
    processRecurringPayment,
    saveInvestmentPayment,
    handleInvestmentProcessing,
    setupPaystackPayment
  } = usePaymentHandling({
    setCards,
    selectedOption,
    user,
    totalPrice,
    onClose,
    paystackPublicKey,
    watch,
    plan,
    type
  })

  useEffect(() => {
    if (user?.id) fetchCards({ user_id: user.id })
  }, [user, fetchCards])

  const resetCardFields = () => {
    reset(
      {
        cardnumber: '',
        cvv: '',
        expirydate: ''
      },
      { keepValues: true }
    )
  }

  const populateCardFields = (selectedOption) => {
    const card = findCardById(cards, selectedOption.split('_')[1])
    if (card) {
      setValue('cardnumber', card.card_number)
      setValue('cvv', card.cvv)
      setValue('expirydate', card.expiry_date)
    }
  }

  const findCardById = (cards, cardId) =>
    cards.find((card) => card.id === cardId)

  const onSubmit = (data) => {
    if (selectedOption === 'paystack') {
      setupPaystackPayment({
        key: paystackPublicKey,
        email: user?.email,
        amount: totalPrice * 100,
        callback: (response) => {
          saveInvestmentPayment({
            type: selectedPlan.type,
            plan: 'Pro',
            payment_reference: response.reference
          })
          handlePostPaymentActions()
        },
        onClose: () => toast.error('Payment process was closed.')
      })
    } else if (selectedOption?.startsWith('card_')) {
      const card = findCardById(cards, selectedOption.split('_')[1])
      processRecurringPayment({
        card_id: card.id,
        type: selectedPlan.type,
        amount: totalPrice,
        plan: 'Pro'
      })
      handlePostPaymentActions()
    } else if (
      showCardFields &&
      data.cardnumber &&
      data.expirydate &&
      data.cvv
    ) {
      const [month, year] = data.expirydate.split('/')
      setupPaystackPayment({
        key: paystackPublicKey,
        email: user?.email,
        amount: totalPrice * 100,
        card: {
          number: data.cardnumber,
          cvv: data.cvv,
          expiry_month: month,
          expiry_year: year
        },
        callback: (response) => {
          handleInvestmentProcessing(
            'Account Upgraded successfully',
            response,
            data
          )
          handlePostPaymentActions()
        },
        onClose: () => toast.error('Payment process was closed.')
      })
    } else {
      toast.error(
        'Please select a payment method or enter complete card details.'
      )
    }
  }

  if (!isOpen) return null

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
      <div className='bg-white w-[90%] max-w-[500px] rounded-lg text-[#444444] shadow-lg p-4 relative'>
        {showCardFields ? (
          <AddCard
            setSelectedOption={setSelectedOption}
            setShowCardFields={setShowCardFields}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            errors={errors}
            isDirty={isDirty}
            isValid={isValid}
            register={register}
          />
        ) : (
          <>
            <h1 className='text-black ts w-full center text-center font-[500]'>
              Upgrade to Pro Plan
            </h1>
            <button
              onClick={onClose}
              className='absolute top-4 right-2 hover:text-gray-700'
            >
              <X size={20} />
            </button>

            <form className='w-full mt-4' onSubmit={handleSubmit(onSubmit)}>
              <div className='flex items-center justify-between'>
                <div
                  onClick={() => handlePlanClick('monthly', 5000)}
                  className={`cursor-pointer mr-4 w-1/2 text-center py-3 rounded-lg border ${
                    selectedPlan.type === 'monthly'
                      ? 'bg-darkgreen text-white'
                      : 'bg-[#fafafa] text-black'
                  }`}
                >
                  <h3 className='text-lg font-bold'>Monthly Plan</h3>
                  <p>₦5,000</p>
                </div>

                {/* Yearly Plan */}
                <div
                  onClick={() => handlePlanClick('yearly', 60000)}
                  className={`cursor-pointer w-1/2 text-center py-3 rounded-lg border
                     ${
                       selectedPlan.type === 'yearly'
                         ? 'bg-darkgreen text-white'
                         : 'bg-[#fafafa] text-black'
                     }
                  `}
                >
                  <h3 className='text-lg font-bold'>Yearly Plan</h3>
                  <p>₦60,000</p>
                </div>
              </div>

              <PaymentOptions
                cards={cards}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              {/* Conditionally render Proceed button */}
              <div className='w-full mt-3'>
                <button type='submit' className='ts activebutton'>
                  Proceed to make payment
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  )
}

export default PlanUpgrade
