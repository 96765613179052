import React, { useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import useBackendService from '../../../../config/services/backend-service'
import { setLandDetails } from '../../../../config/store/reducers/signUpReducer'
import GlobalButton from '../../SharedComponents/GlobalButton/GlobalButton'
import SelectInput from '../../SharedComponents/SelectInput/SelectInput'
import TextArea from '../../SharedComponents/TextAreaInput/TextAreaInput'
import TextInput from '../../SharedComponents/TextInput/TextInput'
import EnterpriseSignUpConfirmation from './EnterpriseSignUpConfirmation'

const EnterpriseExisitingLandForm = () => {
  const dispatch = useDispatch()
  const { landDetails, businessDetails, enterpriseUser } = useSelector(
    (state) => state.signUp
  )

  const landQuestion = landDetails ? landDetails.question : ''
  const landSize = landDetails ? landDetails.landsize : ''
  const agriResources = landDetails ? landDetails.agricresources : ''
  const resourcesList = landDetails ? landDetails.resourceslist : ''
  const resourcesQuestion = landDetails ? landDetails.resourcesquestion : ''
  const info = landDetails ? landDetails.info : ''
  const information = landDetails ? landDetails.information : ''
  const enterpriseBudget = businessDetails ? businessDetails.budget : ''
  const enterpriseLocationChoice = businessDetails
    ? businessDetails.locationchoice
    : ''
  const enterpriseVenture = businessDetails ? businessDetails.venture : ''
  const enterpriseBusinessType = businessDetails
    ? businessDetails.businesstype
    : ''
  const [success, setSuccess] = useState(false)

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      question: landQuestion,
      landsize: landSize,
      agricresources: agriResources,
      resourceslist: resourcesList,
      resourcesquestion: resourcesQuestion,
      info,
      information
    }
  })

  const question = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ]
  const resourcesquestion = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ]

  const selectedAnswer = watch('question')
  const noResourcesQuestion = watch('resourcesquestion')
  const noInformation = watch('information')
  const noValidInput = noInformation && noResourcesQuestion

  const { mutate, isLoading } = useBackendService('/auth/farm', 'post', {
    onSuccess: (data) => {
      setSuccess(true)
    },
    onError: (error) => {
      if (error?.response?.data?.message === 'Email already exists') {
        toast.error('This email is already registered. Please use another one.')
      } else {
        toast.error('Something went wrong. Please try again.')
      }
    }
  })

  const onSubmit = async (data) => {
    let filteredData

    if (selectedAnswer === 'Yes') {
      const { question, landsize, agricresources, resourceslist, info } = data
      filteredData = { question, landsize, agricresources, resourceslist, info }
    } else if (selectedAnswer === 'No') {
      const { question, resourcesquestion, information } = data
      filteredData = { question, resourcesquestion, information }
    }
    // Dispatch the form data to Redux store
    dispatch(setLandDetails({ ...filteredData }))

    const payload = {
      email: enterpriseUser?.email,
      isParentFarm: true,
      additionalData: {
        existing_land: landQuestion,
        land_size: landSize,
        agri_resources: agriResources,
        resources_list: resourcesList,
        resources_question: resourcesQuestion,
        info,
        information,
        enterprise_budget: enterpriseBudget,
        enterprise_location_choice: enterpriseLocationChoice,
        enterprise_venture: enterpriseVenture,
        enterprise_business_type: enterpriseBusinessType
      }
    }
    mutate(payload)
    console.log('Filtered data:', filteredData)
  }
  const hasResources = useWatch({ control, name: 'agricresources' })
  // React.useEffect(() => {
  //   if (hasResources === 'No') {
  //     setValue('resourceslist', '');
  //     clearErrors('resourceslist');
  //   }
  // }, [hasResources, setValue, clearErrors]);
  const yesValidInput = agriResources && landSize && resourcesList && info
  return (
    <div className='w-full h-[90vh] lg:h-full'>
      {success
        ? (
          <EnterpriseSignUpConfirmation />
          )
        : (
          <>
            <h1 className='header-text'>Business Details</h1>
            <p className='ts text-[#666666]'>
              This details would help curate a personalized experience for you.
            </p>

            <form
              onSubmit={handleSubmit(onSubmit)}
              className='w-full space-y-4 mt-6'
            >
              <SelectInput
                control={control}
                Controller={Controller}
                data={question}
                title='Do you have existing land for agribusiness?'
                name='question'
                placeholder='Select answer'
              />

              {selectedAnswer === 'Yes' && (
                <div className='w-full space-y-6'>
                  <TextInput
                    name='landsize'
                    register={register}
                    errors={errors}
                    text='What is the size of the land?'
                    placeholder='Input product'
                  />
                  <SelectInput
                    control={control}
                    Controller={Controller}
                    data={resourcesquestion}
                    title='Do you have any available agricultural resources or equipment?'
                    name='agricresources'
                    placeholder='Select answer'
                  />
                  {hasResources === 'Yes' && (
                    <>
                      <TextInput
                        name='resourceslist'
                        register={register}
                        errors={errors}
                        text='List your available resources or equipment'
                        placeholder='Input product'
                      />
                      <div className='w-full'>
                        <TextArea
                          name='info'
                          register={register}
                          errors={errors}
                          text='Any other additional information?'
                          placeholder='Input here'
                        />
                      </div>
                    </>
                  )}

                  {hasResources === 'No' && (

                    <div className='w-full'>
                      <TextArea
                        name='info'
                        register={register}
                        errors={errors}
                        text='Any other additional information?'
                        placeholder='Input here'
                      />
                    </div>
                  )}

                  <GlobalButton
                    isFilled={yesValidInput}
                    title='Submit'
                    isLoading={isLoading}
                  />
                  <p className='login-text'>
                    Have an account?{' '}
                    <a href='/login' className='login-link'>
                      Log in
                    </a>
                  </p>
                </div>
              )}
              {selectedAnswer === 'No' && (
                <div className='w-full space-y-6'>
                  <SelectInput
                    control={control}
                    Controller={Controller}
                    data={resourcesquestion}
                    title='Do you have any available agricultural resources or equipment?'
                    name='resourcesquestion'
                    placeholder='Select answer'
                  />
                  <div className='w-full'>
                    <TextArea
                      name='information'
                      register={register}
                      errors={errors}
                      text='Any other additional information?'
                      placeholder='Input here'
                    />
                  </div>
                  <GlobalButton
                    isFilled={noValidInput}
                    title='Submit'
                    isLoading={isLoading}
                  />
                  <p className='login-text'>
                    Have an account?{' '}
                    <a href='/login' className='login-link'>
                      Log in
                    </a>
                  </p>
                </div>
              )}
            </form>
          </>
          )}
    </div>
  )
}

export default EnterpriseExisitingLandForm
