import { useRef } from 'react'
import {
  AnimatePresence,
  motion,
  useInView
} from 'framer-motion'

const BlurFade = ({
  children,
  className,
  variant,
  duration = 0.4,
  delay = 0,
  xOffset = 0, // Horizontal offset
  yOffset = 0, // Vertical offset
  inView = false,
  inViewMargin = '-50px',
  blur = '6px'
}) => {
  const ref = useRef(null)
  const inViewResult = useInView(ref, { once: true, margin: inViewMargin })
  const isInView = !inView || inViewResult

  // Default animation variants
  const defaultVariants = {
    hidden: { x: xOffset, y: yOffset, opacity: 0, filter: `blur(${blur})` },
    visible: { x: 0, y: 0, opacity: 1, filter: 'blur(0px)' }
  }

  const combinedVariants = variant || defaultVariants

  return (
    <AnimatePresence>
      <motion.div
        ref={ref}
        initial='hidden'
        animate={isInView ? 'visible' : 'hidden'}
        exit='hidden'
        variants={combinedVariants}
        transition={{
          delay: 0.04 + delay,
          duration,
          ease: 'easeOut'
        }}
        className={className}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}

export default BlurFade
