import React from 'react'

const SingleTextArea = ({
  label,
  register,
  name,
  validation,
  placeholder = 'Write a short description',
  error,
  className = ''
}) => {
  return (
    <div className='grid w-full items-center gap-1.5'>
      {label && <label className='ts'>{label}</label>}
      <textarea
        {...register(name, validation)}
        placeholder={placeholder}
        className={`outline-none p-3 rounded-lg overflow-hidden bg-[#F3F5F6] h-[80px] lg:h-[160px] ts ${className}`}
      />
      {error && <p className='error-text'>{error.message}</p>}
    </div>
  )
}

export default SingleTextArea
