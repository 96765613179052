import { X } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../config/contexts/authProvider'
import {
  AddCard,
  PaymentOptions,
  usePaymentHandling
} from '../../../SharedComponents/payment'

function ModalForUserInvestAccount ({ isOpen, onClose, units, price, id }) {
  const { user } = useAuth()
  const paystackPublicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
  const [cards, setCards] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)
  const [showCardFields, setShowCardFields] = useState(false)

  useEffect(() => {
    if (selectedOption === 'add_card') {
      setShowCardFields(true)
      resetCardFields()
    } else if (selectedOption?.startsWith('card_')) {
      setShowCardFields(false)
      populateCardFields(selectedOption)
    } else {
      setShowCardFields(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, cards])

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isDirty, isValid }
  } = useForm({ mode: 'onChange' })

  const unit = watch('unit', 0)
  const totalPrice = parseInt(unit || 0, 10) * price

  const {
    fetchCards,
    processRecurringPayment,
    saveInvestmentPayment,
    handleInvestmentProcessing,
    setupPaystackPayment
  } = usePaymentHandling({
    setCards,
    selectedOption,
    user,
    totalPrice,
    onClose,
    paystackPublicKey,
    watch,
    id,
    unit
  })

  useEffect(() => {
    if (user?.id) fetchCards({ user_id: user.id })
  }, [user, fetchCards])

  const resetCardFields = () => {
    reset(
      {
        cardnumber: '',
        cvv: '',
        expirydate: ''
      },
      { keepValues: true }
    )
  }

  const populateCardFields = (selectedOption) => {
    const card = findCardById(cards, selectedOption.split('_')[1])
    if (card) {
      setValue('cardnumber', card.card_number)
      setValue('cvv', card.cvv)
      setValue('expirydate', card.expiry_date)
    }
  }

  const findCardById = (cards, cardId) =>
    cards.find((card) => card.id === cardId)

  const onSubmit = (data) => {
    if (selectedOption === 'paystack') {
      setupPaystackPayment({
        key: paystackPublicKey,
        email: user?.email,
        amount: totalPrice * 100,
        callback: (response) => {
          saveInvestmentPayment({
            type: 'investment',
            investment_id: id,
            units: data.unit,
            payment_reference: response.reference
          })
        },
        onClose: () => toast.error('Payment process was closed.')
      })
    } else if (selectedOption?.startsWith('card_')) {
      const card = findCardById(cards, selectedOption.split('_')[1])
      processRecurringPayment({
        card_id: card.id,
        type: 'investment',
        amount: totalPrice,
        investment_id: id,
        units: data.unit
      })
    } else if (
      showCardFields &&
      data.cardnumber &&
      data.expirydate &&
      data.cvv
    ) {
      const [month, year] = data.expirydate.split('/')
      setupPaystackPayment({
        key: paystackPublicKey,
        email: user?.email,
        amount: totalPrice * 100,
        card: {
          number: data.cardnumber,
          cvv: data.cvv,
          expiry_month: month,
          expiry_year: year
        },
        callback: (response) => {
          handleInvestmentProcessing('Payment successful!', response, data)
        },
        onClose: () => toast.error('Payment process was closed.')
      })
    } else {
      toast.error(
        'Please select a payment method or enter complete card details.'
      )
    }
  }

  if (!isOpen) return null

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
      <div className='bg-white w-[90%] max-w-[500px] rounded-lg text-[#444444] shadow-lg p-4 relative'>
        {showCardFields ? (
          <AddCard
            setSelectedOption={setSelectedOption}
            setShowCardFields={setShowCardFields}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            errors={errors}
            isDirty={isDirty}
            isValid={isValid}
            register={register}
          />
        ) : (
          <>
            <h1 className='text-black ts w-full center text-center font-[500]'>
              Invest
            </h1>
            <button
              onClick={onClose}
              className='absolute top-4 right-2 hover:text-gray-700'
            >
              <X size={20} />
            </button>

            <form className='w-full mt-4' onSubmit={handleSubmit(onSubmit)}>
              <div className='flex items-center justify-between'>
                <label htmlFor='unit'>
                  How many units would you like to buy?
                </label>
                <span className='text-sm text-gray-500'>
                  {units} units available
                </span>
              </div>

              <input
                type='number'
                id='unit'
                {...register('unit', {
                  required: 'Unit is required',
                  min: { value: 1, message: 'Enter at least 1 unit' },
                  max: {
                    value: units,
                    message: `You cannot buy more than ${units} units`
                  },
                  pattern: { value: /^\d+$/, message: 'Enter a valid number' }
                })}
                placeholder='Enter units'
                className='w-full email-input bg-gray-100 ts'
              />
              {errors.unit && (
                <p className='text-red-500 text-xs mt-1'>{errors.unit.message}</p>
              )}
              <div className='w-full flex justify-between items-center ts mt-3'>
                <h1>Price per share</h1>
                <h1>₦{price.toLocaleString()}</h1>
              </div>
              {totalPrice > 0 && (
                <>
                  <div className='w-full flex justify-between items-center ts mt-3'>
                    <h1>Estimated amount</h1>
                    <h1>₦{totalPrice.toLocaleString()}</h1>
                  </div>
                  <PaymentOptions
                    cards={cards}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                  />
                </>
              )}
              {/* Conditionally render Proceed button */}
              {unit > 0 && (
                <div className='w-full mt-3'>
                  <button
                    type='submit'
                    className='ts activebutton'
                    // disabled={!(isDirty && isValid)}
                  >
                    Invest
                  </button>
                </div>
              )}
            </form>
          </>
        )}
      </div>
    </div>
  )
}

export default ModalForUserInvestAccount
