import { ArrowLeft } from 'lucide-react'
import React, { useState } from 'react'
import Products from './Products'
import Catalog from './Catalog'
import Sales from './Sales'
import NavigvationSteps from '../NavigationSteps/NavigvationSteps'
import { useForm } from 'react-hook-form'
import Modal from '../../../SharedComponents/Modal/Modal'
import useBackendService from '../../../../../config/services/backend-service'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetLocations,
  resetProductCatalog,
  setLocations,
  setProductCatalog,
  setStage
} from '../../../../../config/store/reducers/productCatalog'

function AddNewProductCatalog () {
  const dispatch = useDispatch()
  const productCatalog = useSelector((state) => state.productCatalog)
  const stage = useSelector((state) => state.productCatalog.stage)
  const location = useSelector((state) => state.productCatalog.locations)
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false
  })
  const [images, setImages] = useState([])

  const handleNextStage = () => {
    if (stage < 3) {
      dispatch(setStage(stage + 1))
    }
  }

  const handlePrevStage = () => {
    if (stage > 1) {
      dispatch(setStage(stage - 1))
    } else {
      dispatch(setStage(1))
    }
  }

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isValid, isDirty }
  } = useForm({ mode: 'onChange' })

  const { mutate: createProductCatalog, isLoading } = useBackendService(
    '/product_catalogues',
    'post',
    {
      onSuccess: () => {
        setModal({
          title: 'Product Catalog added',
          desc: 'Well done, you have successfully created a new product catalog.',
          type: 'success',
          link: '/admin-products',
          visible: true
        })
        setModalOpen(true)
        dispatch(resetProductCatalog())
        dispatch(resetLocations())
        reset()
      },
      onError: () => {
        setModal({
          type: 'error',
          title: 'Submission failed',
          desc: 'You are unable to create a product catalog.',
          visible: true
        })
        setModalOpen(true)
      }
    }
  )
  console.log(productCatalog)
  const onSubmit = async (data) => {
    dispatch(setProductCatalog(data))
    const formData = new FormData()
    formData.append('name', productCatalog.name)
    formData.append('category', productCatalog.category)
    formData.append('description', productCatalog.description)
    formData.append(
      'nutritionMarketValue',
      JSON.stringify(productCatalog.nutritionMarketValue)
    )
    formData.append('growingTips', JSON.stringify(productCatalog.growingTips))
    formData.append('careInstructions', JSON.stringify(productCatalog.careInstructions))
    formData.append('storageSolutions', productCatalog.storageSolutions)
    formData.append('packagingMethods', productCatalog.packagingMethods)
    formData.append('marketPrices', JSON.stringify(productCatalog.marketPrices))
    formData.append('logisticsProviders', productCatalog.logisticsProviders)
    formData.append('salesChannels', productCatalog.salesChannels)
    location.forEach((loc, index) => {
      formData.append(`locations[${index}]`, loc)
    })

    images.forEach((image, index) => {
      formData.append('images', image.file)
    })
    console.log(formData)
    createProductCatalog(formData)
  }

  return (
    <section className='p-5 lg:p-10 flex flex-col gap-[15px] lg:gap-[30px] bg-white'>
      <div className='mb-2 lg:mb-4'>
        <a href='/admin-products'>
          <ArrowLeft size={24} />
        </a>
      </div>
      <div className='space-y-0 lg:space-y-2'>
        <h1 className='text-[16px] lg:text-xl text-black'>Add product catalog</h1>
        <p className='text-[#666666] text-sm lg:text-sm'>
          Enter product details to add
        </p>
      </div>
      <NavigvationSteps stage={stage} />
      <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
        {stage === 1 && (
          <Products
            onClick={handleNextStage}
            register={register}
            errors={errors}
            control={control}
            location={location}
            setLocation={(locations) => dispatch(setLocations(locations))}
            productCatalog={productCatalog}
          />
        )}
        {stage === 2 && (
          <Catalog
            handleNext={handleNextStage}
            handlePrev={handlePrevStage}
            register={register}
            errors={errors}
            isValid={isValid}
            productCatalog={productCatalog}
          />
        )}
        {stage === 3 && (
          <Sales
            handlePrev={handlePrevStage}
            register={register}
            errors={errors}
            isValid={isValid}
            isLoading={isLoading}
            isDirty={isDirty}
            productCatalog={productCatalog}
            setImages={setImages}
            images={images}
          />
        )}
      </form>
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </section>
  )
}

export default AddNewProductCatalog
