import { ChevronDown } from 'lucide-react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import useBackendService from '../../../../config/services/backend-service'
import { setCeoDetails } from '../../../../config/store/reducers/signUpReducer'
import GlobalButton from '../../SharedComponents/GlobalButton/GlobalButton'
import TextInput from '../../SharedComponents/TextInput/TextInput'
import EnterpriseBusinessTypeSelector from './EnterpriseBusinessTypeSelector'
import EnterpriseSignUpConfirmation from './EnterpriseSignUpConfirmation'

const EnterpriseCeoRegistration = () => {
  const [selectedValue, setSelectedValue] = useState('')
  const [success, setSuccess] = useState(false)
  const dispatch = useDispatch()
  const { ceoDetails, farmDetails, enterpriseUser } = useSelector(
    (state) => state.signUp
  )
  const ceoFullName = ceoDetails ? ceoDetails.ceoname : ''
  const ceoCacNumber = ceoDetails ? ceoDetails.cacnumber : ''
  const ceoFullEmail = ceoDetails ? ceoDetails.ceoemail : ''
  const ceoBusinessType = ceoDetails ? ceoDetails.ceobusinesstype : ''
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      businesstype: ceoBusinessType,
      ceoname: ceoFullName,
      ceoemail: ceoFullEmail,
      cacnumber: ceoCacNumber
    }
  })

  const handleSave = () => {
    if (selectedValue) {
      const selectedSubtypes = Object.keys(selectedValue)
      setValue('businesstype', selectedSubtypes, { shouldValidate: true })
    }
  }
  const isFormFilled = ceoFullName && ceoBusinessType && ceoCacNumber && ceoFullEmail
  const { mutate, isLoading } = useBackendService('/auth/farm', 'post', {
    onSuccess: (data) => {
      setSuccess(true)
    },
    onError: (error) => {
      if (error?.response?.data?.message === 'Email already exists') {
        toast.error('This email is already registered. Please use another one.')
      } else {
        toast.error('Something went wrong. Please try again.')
      }
    }
  })

  const onSubmit = async (data) => {
    dispatch(
      setCeoDetails({
        ceoname: data.ceoname,
        ceoemail: data.ceoemail,
        cacnumber: data.cacnumber,
        ceobusinesstype: data.businesstype
      })
    )
    const payload = {
      email: enterpriseUser?.email,
      phoneNumber: farmDetails.phoneNumber,
      isParentFarm: farmDetails.isParentFarm || true,
      name: farmDetails.farmname,
      state: farmDetails.location,
      additionalData: {
        staffs: farmDetails.staffs,
        branches: farmDetails.branch,
        year_founded: farmDetails.year,
        type: data.businesstype,
        ceo_name: data.ceoname,
        ceo_email: data.ceoemail,
        cac_number: data.cacnumber
      }
    }
    mutate(payload)
  }

  return (
    <div className='content-cover'>
      {/* Conditionally render success content */}
      {success ? (
        <EnterpriseSignUpConfirmation />
      ) : (
        // Show form if success is false
        <>
          <div className='w-full space-y-1'>
            <h1 className='header-text'>Business Details</h1>
            <p className='text-black ts'>
              This details would help curate a personalized experience for you.
            </p>
          </div>

          <form className='form-wrapper' onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              name='ceoname'
              register={register}
              errors={errors}
              text='CEO name'
              placeholder='CEO name'
              validation={{ required: 'CEO name is required' }}
            />

            <TextInput
              name='ceoemail'
              register={register}
              errors={errors}
              text='CEO email'
              placeholder='CEO email'
              type='email'
              validation={{ required: 'CEO email is required' }}
            />

            <TextInput
              name='cacnumber'
              register={register}
              errors={errors}
              text='CAC number'
              placeholder='Input CAC number'
              validation={{ required: 'CAC number is required' }}
            />

            <div
              className='w-full'
              onClick={() => document.getElementById('business').showModal()}
            >
              <TextInput
                name='businesstype'
                register={register}
                errors={errors}
                text='Type of Business'
                dvalue={selectedValue || ceoBusinessType}
                placeholder='Select business type'
                calendaricon={<ChevronDown size={20} />}
                validation={{ required: 'Business type is required' }}
              />
            </div>

            <GlobalButton
              isFilled={isFormFilled}
              isLoading={isLoading}
              title='Submit '
            />
            <EnterpriseBusinessTypeSelector
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              save={handleSave}
            />
          </form>

          <p className='login-text'>
            Have an account?{' '}
            <a href='/login' className='login-link'>
              Log in
            </a>
          </p>
        </>
      )}
    </div>
  )
}

export default EnterpriseCeoRegistration
