import { combineReducers } from 'redux'
import authReducer from './authReducer'
import signUpReducer from './signUpReducer'
import productCatalogReducer from './productCatalog'

export default combineReducers({
  auth: authReducer,
  signUp: signUpReducer,
  productCatalog: productCatalogReducer

})
