import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import useBackendService from '../../../../../config/services/backend-service'
import BackButton from '../../../SharedComponents/BackButton/BackButton'
import ImageUpload from '../../../SharedComponents/ImageUpload/ImageUpload'
import Modal from '../../../SharedComponents/Modal/Modal'
import GlobalButton from '../../../SharedComponents/GlobalButton/GlobalButton'
import SingleTextInput from '../../../SharedComponents/TextInput/SingleTextInput'
import SingleTextArea from '../../../SharedComponents/TextAreaInput/SingleTextArea'

const AdminCreateReport = () => {
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onChange'
  })
  const { id } = useParams()

  const [images, setImages] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false // Add a visible property for modal
  })

  const { mutate: submitReport, isLoading } = useBackendService(
    '/admin/admin_report',
    'post',
    {
      onSuccess: () => {
        setModal({
          title: 'Report Submitted Successfully',
          desc: 'Your report has been created and stored in the system.',
          type: 'success',
          link: '/admin-consultations',
          visible: true
        })
        setModalOpen(true)
        reset()
        setImages([])
      },
      onError: (error) => {
        setModal({
          type: 'error',
          title: 'Submission Failed',
          desc: `Unable to create report. ${
            error.data.message || 'Please try again.'
          }`,
          visible: true
        })
        alert('l')
        setModalOpen(true)
      }
    }
  )

  const onSubmit = async (data) => {
    const formData = new FormData()

    formData.append('amount', data.amount)
    formData.append('description', data.description)
    formData.append('farmId', id)

    images.forEach((image, index) => {
      formData.append('files', image.file)
    })

    submitReport(formData)
  }
  const amount = watch('amount')
  const description = watch('description')
  const isfilled = amount && description && images.length > 0
  return (
    <div className='w-full p-[2%] bg-white'>
      <BackButton />
      <div className='w-full pt-2'>
        <h1 className='text-[18px] font-[500] text-darkGreen'>Create report</h1>
        <h1 className='text-[14px] font-[400] text-[#666666]'>
          Report overview
        </h1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className='w-full pt-4 space-y-4'>
        <div className='w-[30%]'>
           <SingleTextInput
                label='Amount charged'
                name='amount'
                type='number'
                register={register}
                errors={errors}
                validationRules={{ required: 'Amount is required' }}
                placeholder='Input here'
              />
        </div>
        <div className='w-[80%]'>
          <SingleTextArea
                label='Description'
                name='description'
                register={register}
                validation={{ required: 'Description are required' }}
                error={errors.description}
              />
        </div>
        {/* image file upload */}
        <div className=' w-full lg:w-[80%] space-y-1 '>
          <h1 className='ts'>File Upload</h1>
          <ImageUpload
            name='productImages'
            images={images}
            setImages={setImages}
            oneimageneeded='true'
            allowPdf
          />
        </div>

        <div className='w-[30%]'>

          <GlobalButton
            title='Submit report'
            isFilled={isfilled}
            isLoading={isLoading}
          />
        </div>
      </form>

      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  )
}

export default AdminCreateReport
