import React from 'react'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import SelectInput from '../../../SharedComponents/SelectInput/SelectInput'
import { Controller } from 'react-hook-form'
import RepeatedTextInput from '../../../SharedComponents/TextInput/RepeatedTextInput'
import ImageUpload from '../../../SharedComponents/ImageUpload/ImageUpload'
import { Search } from 'lucide-react'

function Products ({
  control,
  register,
  errors,
  setImages,
  images,
  location,
  setLocation,
  user
}) {
  const roles = [
    { value: 'livestock', label: 'LiveStock' },
    { value: 'tools', label: 'Tools' },
    { value: 'crop', label: 'Crop' }
  ]
  const handleLocationChange = updatedLocations => {
    setLocation(updatedLocations)
  }

  return (
    <form className=' flex flex-col lg:gap-10 gap-2'>
      <div className=' w-full flex items-center flex-col lg:flex-row gap-5 lg:gap-[60px] '>
        <TextInput
          name='name'
          register={register}
          errors={errors}
          text='Name'
          disabled={user.role === 'approver'}
        />
        <SelectInput
          control={control}
          Controller={Controller}
          data={roles}
          title='Category'
          name='category'
          placeholder='Select Category'
        />
      </div>
      <div className='w-full flex items-center flex-col lg:flex-row gap-5 lg:gap-[60px] '>
        <div className=' w-full  '>
          <RepeatedTextInput
            label='Location (s)'
            icon={<Search className=' text-[#dddddd] ' />}
            placeholder='Enter location to grow product'
            existingValues={location}
            onValuesChange={handleLocationChange}
          />
        </div>
      </div>
      <div className=' w-full'>
        <ImageUpload
          name='ProductImages'
          images={images}
          setImages={setImages}
        />
      </div>
    </form>
  )
}

export default Products