import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../config/contexts/authProvider'
import useBackendService from '../../../../../config/services/backend-service'
import GlobalButton from '../../../SharedComponents/GlobalButton/GlobalButton'
import Modal from '../../../SharedComponents/Modal/Modal'
import PasswordInput from '../../../SharedComponents/PasswordInput/PasswordInput'

const ProfileChangePassword = () => {
  const { user } = useAuth()
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false)
  const [newPasswordVisible, setNewPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false // Add a visible property for modal
  })

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm()

  const { mutate: updatePassword, isLoading } = useBackendService(
    '/auth/update_password',
    'post',
    {
      onSuccess: () => {
        setModal({
          type: 'success',
          title: 'Password changed',
          desc: 'Your password has been successfully updated.',
          link: '/',
          visible: true
        })
        setModalOpen(true)
      },
      onError: (error) => {
        const errorMessage =
          error?.response?.data?.message ||
          'There was an issue updating your password. Please try again.'

        setModal({
          type: 'error',
          title: 'Unable to change password',
          desc: errorMessage,
          visible: true
        })
        setModalOpen(true)
      }
    }
  )

  const onSubmit = async (data) => {
    const { currentpassword, newpassword, confirmnewpassword } = data

    if (newpassword !== confirmnewpassword) {
      toast.error('New password and confirmation password do not match')
      return
    }

    try {
      updatePassword({
        id: user.id,
        oldPassword: currentpassword,
        newPassword: newpassword
      })
    } catch (error) {}
  }

  const currentPassword = watch('currentpassword')

  return (
    <div className='border border-gray-300 rounded-md w-full h-full'>
      <h1 className='font-medium p-3'>Change Password</h1>
      <hr />
      <form
        className='lg:w-[80%] p-3 space-y-3'
        onSubmit={handleSubmit(onSubmit)}
      >
        <PasswordInput
          register={register}
          errors={errors}
          label='Enter current password'
          name='currentpassword'
          setShowPassword={setCurrentPasswordVisible}
          showPassword={currentPasswordVisible}
          nobottomlink='Enter current password'
        />
        <PasswordInput
          register={register}
          errors={errors}
          name='newpassword'
          label='Enter new password'
          setShowPassword={setNewPasswordVisible}
          showPassword={newPasswordVisible}
          nobottomlink='Enter new password'
          watch={watch}
          currentPassword={currentPassword}
        />
        <PasswordInput
          register={register}
          errors={errors}
          name='confirmnewpassword'
          label='Confirm new password'
          setShowPassword={setConfirmPasswordVisible}
          showPassword={confirmPasswordVisible}
          nobottomlink='Confirm new password'
          watch={watch}
          compareWith='newpassword'
        />

        <GlobalButton title='Change password' isLoading={isLoading} isFilled />
        <div className='w-full bg-darkGreen text-white ts p-3 rounded-md'>
          <h1>What happens next?</h1>
          <p>
            You will be logged out of your account and will be required to log
            in with your new updated password.
          </p>
        </div>
      </form>
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  )
}

export default ProfileChangePassword
