import { X } from 'lucide-react'
import { toast } from 'react-toastify'
import useBackendService from '../../../../config/services/backend-service'

const AddCard = ({
  setSelectedOption,
  setShowCardFields,
  handleSubmit,
  onSubmit,
  errors,
  isDirty,
  isValid,
  register
}) => {
  return (
    <>
      <h1 className='text-black ts w-full center text-center font-[500]'>
        Add new card
      </h1>
      <button
        onClick={() => {
          setSelectedOption(null)
          setShowCardFields(false)
        }}
        className='absolute top-4 right-2 hover:text-gray-700'
      >
        <X size={20} />
      </button>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid w-full items-center gap-1.5 ts mt-3'>
          <label htmlFor='email'>Card number</label>
          <input
            type='number'
            name='cardnumber'
            placeholder='0000 0000 0000 0000'
            {...register('cardnumber', {
              required: 'Card number is required',
              minLength: {
                value: 16,
                message: 'Card number must be at least 16 digits'
              },
              pattern: {
                value: /^[0-9]+$/,
                message: 'Card number must be a number'
              }
            })}
            className='email-input ts'
            onWheel={(e) => e.target.blur()}
          />

          {errors.cardnumber && (
            <p className='text-red-500 text-xs'>{errors.cardnumber.message}</p>
          )}
        </div>
        <div className='w-full center gap-4 mt-3'>
          <div className='grid w-[50%] items-center gap-1.5 ts'>
            <label htmlFor='email'>Expiry Date</label>
            <input
              type='text'
              name='expirydate'
              placeholder='MM/YY'
              {...register('expirydate', {
                required: 'Expiry date is required',
                pattern: {
                  value: /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
                  message: 'Invalid expiry date format (MM/YY)'
                }
              })}
              className='email-input ts'
            />
            {errors.expirydate && (
              <p className='text-red-500 text-xs'>
                {errors.expirydate.message}
              </p>
            )}
          </div>
          <div className='grid w-[50%] items-center gap-1.5 ts'>
            <label htmlFor='email'>CVV</label>
            <input
              type='text'
              name='cvv'
              placeholder='123'
              maxLength={3}
              {...register('cvv', {
                required: 'CVV is required',
                pattern: {
                  value: /^[0-9]{3}$/,
                  message: 'Invalid CVV format (3 digits)'
                }
              })}
              className='email-input ts'
            />
            {errors.cvv && (
              <p className='text-red-500 text-xs'>{errors.cvv.message}</p>
            )}
          </div>
        </div>

        <button
          type='submit'
          className={`ts mt-4 ${
            isDirty && isValid
              ? 'activebutton'
              : 'h-[50px] w-full rounded-[8px] bg-darkash text-black'
          }`}
          disabled={!(isDirty && isValid)}
        >
          Continue
        </button>
      </form>

      <div className='pt-2 w-full center gap-3 text-[#888888] mt-3 ts'>
        <h1 className='font-[400] text-center'>
          Paystack would require your card details again to process your payment
          successfully.
        </h1>
      </div>
    </>
  )
}

const PaymentOptions = ({ cards, selectedOption, setSelectedOption }) => {
  return (
    <>
      {' '}
      <h1 className='ts mt-3 text-center font-bold'>Select payment method</h1>
      <div className='mt-3 w-full gap-3 '>
        {cards.map((card, index) => (
          <div
            key={index}
            className='mt-2 flex justify-between ps-4 border border-gray-200 rounded dark:border-gray-700'
          >
            <div className='flex items-center'>
              <label
                for='bordered-checkbox-2'
                className='flex w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
              >
                <img
                  alt='img'
                  src={require('../../../resources/mastercard.png')}
                  className='w-[37px] h-[25px]'
                />
                <h1 className='ml-2 left-3 bottom-4 text-black ts'>
                  {' '}
                  **** **** **** {card.card_number.slice(-4)}
                </h1>
              </label>
            </div>

            <input
              type='checkbox'
              id='toggleCardFields'
              checked={selectedOption === `card_${card.id}`}
              onChange={() => setSelectedOption(`card_${card.id}`)}
              className='text-black mt-3 mr-3 w-[15px] h-[15px]'
            />
          </div>
        ))}
        <div className='mt-2 flex justify-between ps-4 border border-gray-200 rounded dark:border-gray-700'>
          <div className='flex items-center'>
            <label
              for='bordered-checkbox-2'
              className='flex w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
            >
              <img
                alt='img'
                src={require('../../../resources/paystack2.png')}
                className='w-[37px] h-[25px]'
              />
              <h1 className='ml-2 left-3 bottom-4 text-black ts'>
                Pay with paystack{' '}
              </h1>
            </label>
          </div>

          <input
            type='checkbox'
            id='toggleCardFields'
            checked={selectedOption === 'paystack'}
            onChange={() => setSelectedOption('paystack')}
            className='text-black mt-3 mr-3 w-[15px] h-[15px]'
          />
        </div>
        <div className='mt-2 flex justify-between ps-4 border border-gray-200 rounded dark:border-gray-700'>
          <div className='flex items-center'>
            <label
              for='bordered-checkbox-2'
              className='flex w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
            >
              <img
                alt='img'
                src={require('../../../resources/card2.png')}
                className='w-[37px] h-[25px]'
              />
              <h1 className='ml-2 left-3 bottom-4 text-black ts'>
                Add new card{' '}
              </h1>
            </label>
          </div>

          <input
            type='checkbox'
            id='toggleCardFields'
            checked={selectedOption === 'add_card'}
            onChange={() => setSelectedOption('add_card')}
            className='text-black mt-3 mr-3 w-[15px] h-[15px]'
          />
        </div>
      </div>
    </>
  )
}

const usePaymentHandling = ({
  setCards,
  selectedOption,
  user,
  totalPrice,
  onClose,
  paystackPublicKey,
  watch,
  plan = null,
  type = null,
  id = null,
  unit = null
}) => {
  const fetchCards = useBackendService('/cards', 'get', {
    onSuccess: (response) => setCards(response.data),
    onError: () => toast.error('Failed to fetch cards.')
  }).mutate

  const processInvestment = useBackendService(
    '/investments/users_investment',
    'post'
  ).mutate

  const saveRecurring = useBackendService(
    '/ipn/paystack/save_recurring',
    'post'
  ).mutate

  const processRecurringPayment = useBackendService(
    '/ipn/paystack/charge_recurring',
    'post',
    {
      onSuccess: () => {
        handleInvestmentProcessing('Payment successful!')
      },
      onError: (error) => handleRecurringPaymentError(error)
    }
  ).mutate

  const saveInvestmentPayment = useBackendService(
    '/ipn/paystack/investment_paystack',
    'post',
    {
      onSuccess: () => {
        handleInvestmentProcessing('Payment successful!')
      },
      onError: () => toast.error('Recurring payment failed. Please try again.')
    }
  ).mutate

  const handleInvestmentProcessing = (
    successMessage,
    response = null,
    data = null
  ) => {
    if (response) {
      if (data) {
        saveRecurring({
          cardNumber: data.cardnumber,
          expiryDate: data.expirydate,
          cvv: data.cvv,
          reference: response.reference,
          type,
          plan
        })
      } else {
        saveRecurring({
          card_id: selectedOption.split('_')[1],
          reference: response.reference,
          type,
          plan
        })
      }
    }
    if (id) {
      processInvestment({
        investment_id: id,
        user_id: user.id,
        units: unit,
        amount: totalPrice,
        status: 'success'
      })
    }

    onClose()
    toast.success(successMessage)
    window.location.reload()
  }

  const handleRecurringPaymentError = (error) => {
    const errorMessage =
      error?.response?.data?.message || 'An unexpected error occurred.'

    if (errorMessage === 'Recurring details not found for the given card ID') {
      toast.warn(
        'Payment details are missing. Redirecting to Paystack to update your payment information.'
      )
      setupPaystackPayment({
        key: paystackPublicKey,
        email: user?.email,
        card: {
          number: watch('cardnumber'),
          cvv: watch('cvv'),
          expiry_month: watch('expirydate').split('/')[0],
          expiry_year: watch('expirydate').split('/')[1]
        },
        amount: totalPrice * 100,
        callback: (response) => {
          handleInvestmentProcessing('Payment successful!', response)
        },
        onClose: () => toast.error('Payment process was closed.')
      })
    } else {
      toast.error(errorMessage)
    }
  }

  const setupPaystackPayment = ({
    key,
    email,
    amount,
    card,
    callback,
    onClose
  }) => {
    const paystack = window.PaystackPop.setup({
      key,
      email,
      amount,
      card,
      callback,
      onClose
    })
    paystack.openIframe()
  }
  return {
    fetchCards,
    processInvestment,
    saveRecurring,
    processRecurringPayment,
    saveInvestmentPayment,
    handleInvestmentProcessing,
    handleRecurringPaymentError,
    setupPaystackPayment
  }
}

export { AddCard, PaymentOptions, usePaymentHandling }
