import { BellDot, Menu } from 'lucide-react'
import React, { useState } from 'react'
import { RegularDashboardContent } from '../../../data/static'
import NotificationDrawer from '../../SharedComponents/NotificationDrawer/NotificationDrawer'
import './NavBar.css'

const NavBar = ({ location, onClick }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const { pathname } = location
  const navData = RegularDashboardContent.find((item) => item.link === pathname)

  const { text, subtext } = navData || {}
  return (
    <div className='pro-topnav-container'>
      <div className='flex flex-col gap-3 lg:gap-[15px] min-w-fit'>
        <p className=' text-black lg:text-darkgreen font-[700] lg text-[20px] lg:text-[22px] '>
          {text}
        </p>
        <p
          style={{ width: '70vw' }}
          className='text-[#444444] lg:text-darkgreen font-[400] text-[12px] '
        >
          {subtext}
        </p>
      </div>
      <div className='pronav-content-style'>
        <div className='center gap-2 text-gray-500'>
          <div className='bell-style'>
            <BellDot size={20} onClick={toggleDrawer} />
          </div>
        </div>
      </div>
      <div className=' flex lg:hidden ' onClick={onClick}>
        <Menu size={24} />
      </div>

      <NotificationDrawer
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
      />
    </div>
  )
}

export default NavBar
